import { useEffect, useState } from "react";
import { useReadLocationsQuery } from "features/organisation/domain/reducers/organisation-unit.reducer";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";

export interface ConnectorOrganisationHook {
  locations: OrganisationUnit[];
  readLocationsIsSuccess: boolean;
  readLocationsIsLoading: boolean;

  selectedLocations: OrganisationUnit[];
  onLocationSelected: (selectedLocation: OrganisationUnit) => void;
  onLocationDeselected: (deselectedLocation: OrganisationUnit) => void;

  searchBarValue: string;
  onSearchValueChanged: (newSearchValue: string) => void;
}

const useConnectorOrganisation = (): ConnectorOrganisationHook => {
  const { connectorDetailsHook } = useConnectorsContextProvider();
  const { form } = connectorDetailsHook;

  const [selectedLocations, setSelectedLocations] = useState<
    OrganisationUnit[]
  >(form.getValues("organisationUnits") ?? []);
  const [locations, setLocations] = useState<OrganisationUnit[]>([]);

  const [searchBarValue, setSearchBarValue] = useState<string>("");

  const filterLocations = (
    locations: OrganisationUnit[],
    searchValue: string,
  ): OrganisationUnit[] => {
    return locations.filter((x) =>
      x.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  };

  const onSearchValueChanged = (newSearchValue: string): void => {
    if (readLocationsData) {
      setSearchBarValue(newSearchValue);
      setLocations(filterLocations(readLocationsData, newSearchValue));
    }
  };

  const {
    data: readLocationsData,
    isSuccess: readLocationsIsSuccess,
    isLoading: readLocationsIsLoading,
  } = useReadLocationsQuery();

  useEffect(() => {
    if (readLocationsData) {
      setLocations(readLocationsData);
    }
  }, [readLocationsData]);

  const onLocationSelected = (selectedLocation: OrganisationUnit): void => {
    setSelectedLocations([...selectedLocations, selectedLocation]);
  };
  const onLocationDeselected = (deselectedLocation: OrganisationUnit): void => {
    setSelectedLocations(
      selectedLocations.filter((item) => item.id !== deselectedLocation.id),
    );
  };

  return {
    locations,
    readLocationsIsSuccess,
    readLocationsIsLoading,

    selectedLocations,
    onLocationSelected,
    onLocationDeselected,

    searchBarValue,
    onSearchValueChanged,
  };
};

export default useConnectorOrganisation;
