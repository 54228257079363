import { Dialog } from "@mui/material";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import { FieldValues, FormProvider } from "react-hook-form";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";
import { Connector, ConnectorType } from "features/connectors/domain/connector";
import ConnectorDetailsPopupHeader from "./connector-details-popup-header";
import ConnectorInformation from "./connector-information.component";
import ConnectorSettings from "./connector-settings/connector-settings";

const ConnectorDetailsPopup = (): JSX.Element => {
  const { connectorDetailsHook } = useConnectorsContextProvider();
  const {
    currentSelectedConnector,
    submitConnector,
    isDetailsOpen,
    closeDetails,
    form,
  } = connectorDetailsHook;

  const submitForm = (fieldValues: FieldValues) => {
    const connector: Connector = {
      id: currentSelectedConnector?.id,
      name: currentSelectedConnector?.name ?? "",
      type: currentSelectedConnector?.type ?? ConnectorType.Unknown,
      note: fieldValues.note?.trim(),
      organisationUnits: fieldValues.organisationUnits ?? [],
    };

    submitConnector(connector);
  };

  const popUpStyle = {
    style: {
      height: "960px",
      width: "1227px",
      maxWidth: "1227px",
      paddingBottom: "0px",
    },
  };

  return (
    <Dialog
      open={isDetailsOpen}
      onClose={() => closeDetails(form.formState.isDirty)}
      PaperProps={popUpStyle}
    >
      <FormProvider {...form}>
        <DetailsPopUpContent
          onSubmit={form.handleSubmit(submitForm)}
          leftContent={<ConnectorInformation />}
          rightContent={<ConnectorSettings />}
          header={<ConnectorDetailsPopupHeader />}
        />
      </FormProvider>
    </Dialog>
  );
};

export default ConnectorDetailsPopup;
