import {
  setShouldShowConfirmation,
  showConfirmationPopup,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ViewingMode } from "utils/viewing-utils";
import { Connector, ConnectorType } from "features/connectors/domain/connector";
import { useUpdateConnectorMutation } from "features/connectors/domain/reducers/connectors.reducer";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";

export interface ConnectorDetailsHook {
  viewingMode: ViewingMode;
  mutationIsLoading: boolean;

  currentSelectedConnector: Connector | undefined;
  changeCurrentSelectedConnector: (connector: Connector | undefined) => void;

  submitConnector: (connector: Connector) => void;

  cancelMutation: () => void;

  isDetailsOpen: boolean;
  openDetailsInViewingMode: (connector: Connector) => void;
  openDetailsInEditingMode: (connector: Connector) => void;
  closeDetails: (isFormStateDirty: boolean) => void;

  form: UseFormReturn<Partial<Connector>, any>;

  isLocationPickerOpen: boolean;
  openLocationPicker: () => void;
  closeLocationPicker: () => void;
  updateSelectedLocations: (newLocations: OrganisationUnit[]) => void;
}

const useConnectorDetails = (): ConnectorDetailsHook => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {} as Partial<Connector>,
  });

  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");
  const [currentSelectedConnector, setCurrentSelectedConnector] =
    useState<Connector>();
  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState(false);

  const [
    updateConnector,
    {
      isLoading: updateConnectorIsLoading,
      isSuccess: updateConnectorIsSuccess,
      isError: updateConnectorIsError,
      error: updateConnectorError,
      data: updateConnectorData,
    },
  ] = useUpdateConnectorMutation();

  const setFormValuesWithCurrentConnector = () => {
    form.reset({
      name: currentSelectedConnector?.name ?? "",
      note: currentSelectedConnector?.note ?? "",
      type: currentSelectedConnector?.type ?? ConnectorType.Unknown,
      organisationUnits: currentSelectedConnector?.organisationUnits ?? [],
    });
  };

  useEffect(() => {
    setFormValuesWithCurrentConnector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedConnector]);

  useEffect(() => {
    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: form.formState.isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty]);

  useEffect(() => {
    if (updateConnectorIsSuccess && updateConnectorData) {
      setCurrentSelectedConnector(updateConnectorData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConnectorIsSuccess]);

  useEffect(() => {
    if (updateConnectorIsError && updateConnectorError) {
      dispatch(
        setErrorMessage({
          error: updateConnectorError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConnectorIsError]);

  useEffect(() => {
    setMutationIsLoading(updateConnectorIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConnectorIsLoading]);

  const changeCurrentSelectedConnector = (connector: Connector | undefined) => {
    setCurrentSelectedConnector(connector);
  };

  const submitConnector = (connector: Connector) => {
    updateConnector(connector);
  };

  const openDetailsInEditingMode = (connector: Connector | undefined) => {
    setCurrentSelectedConnector(connector);
    setIsDetailsOpen(true);
    setViewingMode("editing");
    setFormValuesWithCurrentConnector();
  };

  const openDetailsInViewingMode = (connector: Connector | undefined) => {
    setCurrentSelectedConnector(connector);
    setIsDetailsOpen(true);
    setViewingMode("viewing");
  };

  const closeDetails = (isFormStateDirty: boolean) => {
    const showConfirmation = viewingMode === "editing" && isFormStateDirty;

    if (showConfirmation) {
      dispatch(
        showConfirmationPopup({
          showInstantly: true,
          confirmActionNextAction: () => {
            setIsDetailsOpen(false);
            setCurrentSelectedConnector(undefined);
            resetForm();
          },
        }),
      );
    } else {
      setIsDetailsOpen(false);
      setCurrentSelectedConnector(undefined);
      resetForm();
    }
  };

  const cancelMutation = () => {
    if (viewingMode === "editing") {
      setViewingMode("viewing");
    }
    setFormValuesWithCurrentConnector();
  };

  const resetForm = () => {
    form.reset({
      name: "",
      note: "",
      type: ConnectorType.Unknown,
      organisationUnits: [],
    });
  };

  const openLocationPicker = (): void => {
    setIsLocationPickerOpen(true);
  };

  const closeLocationPicker = (): void => {
    setIsLocationPickerOpen(false);
  };

  const sortLocations = (locations: OrganisationUnit[]): OrganisationUnit[] => {
    return locations.sort((locationA, locationB) =>
      locationA.name.localeCompare(locationB.name),
    );
  };

  const updateSelectedLocations = (newLocations: OrganisationUnit[]): void => {
    form.setValue("organisationUnits", sortLocations(newLocations), {
      shouldDirty: true,
    });
  };

  return {
    viewingMode,
    mutationIsLoading,

    currentSelectedConnector,
    changeCurrentSelectedConnector,

    submitConnector,

    cancelMutation,

    isDetailsOpen,
    openDetailsInEditingMode,
    openDetailsInViewingMode,
    closeDetails,

    form,

    isLocationPickerOpen,
    openLocationPicker,
    closeLocationPicker,
    updateSelectedLocations,
  };
};

export default useConnectorDetails;
