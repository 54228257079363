import OrganisationTreeNode, {
  findUpstreamParentBranch,
} from "features/organisation/domain/models/organisation-tree-node";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { useEffect, useState } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useController,
} from "react-hook-form";

export interface SystemOrganisationHook {
  isOrganisationPickerOpen: boolean;
  upstreamParentBranch: OrganisationTreeNode[];
  isLinkErrorPopupOpen: boolean;
  organisationUnitField: ControllerRenderProps<
    FieldValues,
    "organisationUnitId"
  >;

  setOrganisationUnit: (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null,
    upstreamBranch: OrganisationTreeNode[] | null,
  ) => void;

  linkOrganisationUnit: () => void;
  unlinkOrganisationUnit: () => void;

  closeLinkErrorPopup: () => void;
  closeOrganisationPicker: () => void;
}

const useSystemOrganisation = (): SystemOrganisationHook => {
  const [isOrganisationPickerOpen, setIsOrganisationPickerOpen] =
    useState(false);
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);
  const [isLinkErrorPopupOpen, setIsLinkErrorPopupOpen] =
    useState<boolean>(false);

  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const { field } = useController({ name: "organisationUnitId" });

  const { systemsDetailsHook } = useSystemsContextProvider();
  const { selectedSystem, viewingMode } = systemsDetailsHook;

  useEffect(() => {
    if (organisationUnitTree && selectedSystem?.organisationUnitId) {
      const upstreamBranch = findUpstreamParentBranch(
        organisationUnitTree,
        selectedSystem.organisationUnitId,
        [],
      );
      setUpstreamParentBranch(upstreamBranch ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitTree, selectedSystem, viewingMode]);

  const setOrganisationUnit = (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null = null,
    upstreamBranch: OrganisationTreeNode[] | null = null,
  ) => {
    field.onChange(selectedNode?.id ?? null);
    setUpstreamParentBranch(upstreamBranch ?? []);
    setIsOrganisationPickerOpen(false);
  };

  const canLinkOrUnlinkOrganisationUnit = () =>
    !selectedSystem?.devices?.some((device) => !!device.organisationUnitId);

  const linkOrganisationUnit = () =>
    canLinkOrUnlinkOrganisationUnit()
      ? setIsOrganisationPickerOpen(true)
      : setIsLinkErrorPopupOpen(true);

  const unlinkOrganisationUnit = () =>
    canLinkOrUnlinkOrganisationUnit()
      ? setOrganisationUnit(field)
      : setIsLinkErrorPopupOpen(true);

  const closeLinkErrorPopup = () => setIsLinkErrorPopupOpen(false);

  const closeOrganisationPicker = () => setIsOrganisationPickerOpen(false);

  return {
    isOrganisationPickerOpen,
    upstreamParentBranch,
    isLinkErrorPopupOpen,
    organisationUnitField: field,

    setOrganisationUnit,
    linkOrganisationUnit,

    unlinkOrganisationUnit,

    closeLinkErrorPopup,
    closeOrganisationPicker,
  };
};

export default useSystemOrganisation;
