import { Close } from "@mui/icons-material";
import CheckRounded from "@mui/icons-material/CheckRounded";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/connectors/views/connector-details/connector-details-popup-header.module.scss";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";

const ConnectorDetailsPopupHeader = (): JSX.Element => {
  const { t } = useTranslation("connectors");

  const { formState } = useFormContext();
  const { connectorDetailsHook } = useConnectorsContextProvider();
  const {
    cancelMutation,
    mutationIsLoading,
    currentSelectedConnector,
    openDetailsInEditingMode,
    closeDetails,
  } = connectorDetailsHook;

  const isViewing = connectorDetailsHook.viewingMode === "viewing";
  const isEditing = connectorDetailsHook.viewingMode === "editing";

  return (
    <div className={styles.container}>
      {isEditing && (
        <>
          <Button
            data-testid="connectorDetailCancelButton"
            variant="text"
            onClick={() => cancelMutation()}
            disabled={mutationIsLoading}
          >
            {t("details.cancelFormButton")}
          </Button>
          <LoadingButton
            data-testid="connectorDetailSubmitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={mutationIsLoading}
            loadingPosition="start"
          >
            {t("details.saveFormButton")}
          </LoadingButton>
        </>
      )}
      {isViewing && (
        <AutorisationWrapper
          atLeastOnePermissionOf={[Permission.UpdateSystemsGatewaysConnectors]}
        >
          <Button
            data-testid="connectorDetailEditButton"
            startIcon={<ModeEditOutlineOutlinedIcon />}
            variant="text"
            onClick={() => openDetailsInEditingMode(currentSelectedConnector!)}
            disabled={mutationIsLoading}
          >
            {t("details.editFormButton")}
          </Button>
        </AutorisationWrapper>
      )}

      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={() => closeDetails(formState.isDirty)}
        data-testid="ConnectorDetailsPopupCloseButton"
      >
        <Close />
      </IconButton>
    </div>
  );
};

export default ConnectorDetailsPopupHeader;
