import FilterChip from "components/filter-chip/filter-chip.component";
import DeviceFilter from "features/device/models/device-filter";
import DeviceFilterValue, {
  FilterValueType,
} from "features/device/models/device-filter-value";
import KeyValuePair from "models/key-value-pair";
import { useTranslation } from "react-i18next";
import "./device-filters.scss";
import { ReactElement } from "react";
import { DeviceType } from "features/device/models/device-type";
import SearchBar from "components/search/searchbar";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Typography } from "@mui/material";
import Constants from "style/constants";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import ReadFilterValuesMonitoring from "../domain/models/read-filter-values-monitoring";
import { RefreshRounded } from "@mui/icons-material";
import DeviceMonitoringFilter from "./components/device-monitoring-filter";

interface IProps {
  filterValues: DeviceFilterValue[];
  monitoringValues: ReadFilterValuesMonitoring[];
  activeFilters: DeviceFilter[];
  activeMonitoringFilters: ReadFilterValuesMonitoring[];
  onOptionsSelected: (filterValueType: FilterValueType, keys: string[]) => void;
  onMonitoringFilterSelected: (selected: boolean, type: MonitoringType) => void;
  onSearchValueChanged: (value: string) => void;
  clearFilters: () => void;
  searchBarValue: string;
  refresh?: () => void;
}

export default function DeviceFilters(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("devices");

  function determineSelectedOptions(
    filterValue: DeviceFilterValue,
  ): KeyValuePair[] {
    let selectedOptions: KeyValuePair[] = [];

    const currentSelectedKeys = props.activeFilters.find(
      (filter) => filter.filterValueType === filterValue.filterValueType,
    )?.values;

    if (currentSelectedKeys) {
      currentSelectedKeys.forEach((currentSelectedKey) => {
        const currentSelectedValue = getFilterValues(filterValue).find(
          (value) => value.key === currentSelectedKey,
        )?.value;

        if (!currentSelectedValue) {
          selectedOptions = [];
          props.clearFilters();
          return;
        }

        selectedOptions.push({
          key: currentSelectedKey,
          value: currentSelectedValue,
        } as KeyValuePair);
      });
    }
    return selectedOptions;
  }

  function determinePlaceHolderText(filterValueType: FilterValueType): string {
    switch (filterValueType) {
      case FilterValueType.Type:
        return t("filters.type");
      case FilterValueType.Source:
        return t("filters.source");
      case FilterValueType.Linked:
        return t("filters.link");
      default:
        return "";
    }
  }

  function getFilterValues(filter: DeviceFilterValue): KeyValuePair[] {
    if (filter.filterValueType === FilterValueType.Type) {
      return filter.values.map((x) => ({
        key: x.key,
        value: t(`deviceTypes.${DeviceType[x.key as keyof typeof DeviceType]}`),
      }));
    }

    if (filter.filterValueType === FilterValueType.Linked) {
      return filter.values.map((x) => ({
        key: x.key,
        value: x.key === "true" ? t(`filters.linked`) : t(`filters.notLinked`),
      }));
    }

    return filter.values;
  }

  function clearFilters(): void {
    props.clearFilters();
  }

  function isMultiValueFilter(value: FilterValueType) {
    return value === FilterValueType.Type || value === FilterValueType.Source;
  }

  function getFirstSelectedOption(
    filterValue: DeviceFilterValue,
  ): KeyValuePair | undefined {
    const options = determineSelectedOptions(filterValue);
    return options.length > 0 ? options[0] : undefined;
  }

  function getCountForType(monitoringType: MonitoringType): number {
    return (
      props.monitoringValues.find((x) => x.monitoringType === monitoringType)
        ?.count ?? 0
    );
  }

  return (
    <div className="device-filters-container">
      <div className="top-row">
        <div className="device-filters">
          <SearchBar {...props} value={props.searchBarValue} />
          {props.filterValues.length > 0 && <FilterListIcon />}
          {props.filterValues.map((filterValue) =>
            isMultiValueFilter(filterValue.filterValueType) ? (
              <MultiValueFilterChip
                key={`multiValuefilterChip${filterValue.filterValueType}`}
                options={getFilterValues(filterValue)}
                selectedOptions={determineSelectedOptions(filterValue)}
                onOptionsSelected={(keys: string[]) =>
                  props.onOptionsSelected(filterValue.filterValueType, keys)
                }
                placeHolder={determinePlaceHolderText(
                  filterValue.filterValueType,
                )}
              />
            ) : (
              <FilterChip
                key={`filterChip${filterValue.filterValueType}`}
                options={getFilterValues(filterValue)}
                onOptionSelected={(key?: string) =>
                  props.onOptionsSelected(
                    filterValue.filterValueType,
                    key ? [key] : [],
                  )
                }
                placeHolder={determinePlaceHolderText(
                  filterValue.filterValueType,
                )}
                initialSelectedOption={getFirstSelectedOption(filterValue)}
              />
            ),
          )}

          {props.filterValues.length > 0 && (
            <div className="clear-filters">
              <Typography
                variant="body1"
                color={Constants.Colors.primary}
                onClick={clearFilters}
              >
                {t("filters.clearFilters")}
              </Typography>
            </div>
          )}
        </div>

        {props.refresh && (
          <IconButton className="refresh-button" onClick={props.refresh}>
            <RefreshRounded />
          </IconButton>
        )}
      </div>
      {props.monitoringValues.length > 0 && (
        <div className="monitoring-filters">
          <DeviceMonitoringFilter.Offline
            count={getCountForType(MonitoringType.Offline)}
            isSelected={
              props.activeMonitoringFilters.find(
                (x) => x.monitoringType === MonitoringType.Offline,
              ) !== undefined
            }
            onSelected={(selected) =>
              props.onMonitoringFilterSelected(selected, MonitoringType.Offline)
            }
          />
          <DeviceMonitoringFilter.Outdated
            count={getCountForType(MonitoringType.OutDated)}
            isSelected={
              props.activeMonitoringFilters.find(
                (x) => x.monitoringType === MonitoringType.OutDated,
              ) !== undefined
            }
            onSelected={(selected) =>
              props.onMonitoringFilterSelected(
                selected,
                MonitoringType.OutDated,
              )
            }
          />
          <DeviceMonitoringFilter.BatteryCritical
            count={getCountForType(MonitoringType.BatteryCritical)}
            isSelected={
              props.activeMonitoringFilters.find(
                (x) => x.monitoringType === MonitoringType.BatteryCritical,
              ) !== undefined
            }
            onSelected={(selected) =>
              props.onMonitoringFilterSelected(
                selected,
                MonitoringType.BatteryCritical,
              )
            }
          />
          <DeviceMonitoringFilter.BatteryLow
            count={getCountForType(MonitoringType.BatteryLow)}
            isSelected={
              props.activeMonitoringFilters.find(
                (x) => x.monitoringType === MonitoringType.BatteryLow,
              ) !== undefined
            }
            onSelected={(selected) =>
              props.onMonitoringFilterSelected(
                selected,
                MonitoringType.BatteryLow,
              )
            }
          />
          <DeviceMonitoringFilter.Online
            count={getCountForType(MonitoringType.Online)}
            isSelected={
              props.activeMonitoringFilters.find(
                (x) => x.monitoringType === MonitoringType.Online,
              ) !== undefined
            }
            onSelected={(selected) =>
              props.onMonitoringFilterSelected(selected, MonitoringType.Online)
            }
          />
          <DeviceMonitoringFilter.NotMonitored
            count={getCountForType(MonitoringType.NotMonitored)}
            isSelected={
              props.activeMonitoringFilters.find(
                (x) => x.monitoringType === MonitoringType.NotMonitored,
              ) !== undefined
            }
            onSelected={(selected) =>
              props.onMonitoringFilterSelected(
                selected,
                MonitoringType.NotMonitored,
              )
            }
          />
        </div>
      )}
    </div>
  );
}
