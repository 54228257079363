import KeyValuePair from "models/key-value-pair";

export default interface DeviceFilterValue {
  filterValueType: FilterValueType;
  values: KeyValuePair[];
}

export enum FilterValueType {
  Type = "Type",
  Source = "Source",
  Linked = "Linked",
}
