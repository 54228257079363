import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { ReadSystemsResponse } from "features/systems/domain/models/read-systems-response";
import { useLazyReadSystemsQuery } from "features/systems/domain/reducers/systems.reducer";
import { System } from "features/systems/domain/models/system";
import useSystemDetails, {
  SystemDetailsHook,
} from "features/systems/hooks/system-details-hook";
import useSystemFilters, {
  SystemFiltersHook,
} from "features/systems/hooks/system-filters-hook";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export interface SystemsHook {
  systemsResponse?: ReadSystemsResponse;
  systemsIsLoading: boolean;
  systemsIsSuccess: boolean;

  systemsFilterHook: SystemFiltersHook;
  systemsDetailsHook: SystemDetailsHook;

  openDetailsInViewingModeAndRefresh: (system: System) => void;
  openDetailsInEditingModeAndRefresh: (system: System) => void;

  refreshSystems: () => void;
}

const useSystems = (): SystemsHook => {
  const dispatch = useDispatch();
  const systemsFilterHook = useSystemFilters();
  const systemsDetailsHook = useSystemDetails();

  const [systemIdToOpen, setSystemIdToOpen] = useState<string>();
  const [shouldOpenDetails, setShouldOpenDetails] = useState<boolean>(false);

  const [
    triggerReadSystems,
    {
      data: systemsResponse,
      error,
      isError,
      isLoading: systemsIsLoading,
      isSuccess: systemsIsSuccess,
    },
  ] = useLazyReadSystemsQuery();

  useEffect(() => {
    if (systemsDetailsHook.selectedSystem) {
      const updatedSystem = systemsResponse?.systems.find(
        (system) => system.id === systemsDetailsHook.selectedSystem!.id,
      );

      updatedSystem &&
        systemsDetailsHook.changeCurrentSelectedSystem(updatedSystem);
    }

    shouldOpenDetails && openDetailsById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsResponse]);

  useEffect(() => {
    triggerReadSystems(systemsFilterHook.readSystemsQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsFilterHook.readSystemsQuery]);

  useEffect(() => {
    if (isError && error) {
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const refreshSystems = (): void => {
    triggerReadSystems(systemsFilterHook.readSystemsQuery);
  };

  const openDetailsById = () => {
    const system = systemsResponse?.systems.find(
      (system) => system.id === systemIdToOpen,
    );

    system && openDetailsInViewingModeAndRefresh(system);
    setSystemIdToOpen(undefined);
    setShouldOpenDetails(false);
  };

  const openDetailsInViewingModeAndRefresh = (system: System) => {
    refreshSystems();
    systemsDetailsHook.openDetailsInViewingMode(system);
  };

  const openDetailsInEditingModeAndRefresh = (system: System) => {
    refreshSystems();
    systemsDetailsHook.openDetailsInEditingMode(system);
  };

  return {
    systemsResponse,
    systemsIsLoading,
    systemsIsSuccess,

    systemsFilterHook,
    systemsDetailsHook,

    openDetailsInViewingModeAndRefresh,
    openDetailsInEditingModeAndRefresh,

    refreshSystems,
  };
};

export default useSystems;
