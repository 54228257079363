import { useDevicesContextProvider } from "features/device/devices/providers/devices-provider/devices.provider";
import Device from "features/device/domain/models/device";
import { useDeleteDeviceMutation } from "features/device/domain/reducers/device.reducer";
import DeviceFilterValues from "features/device/models/device-filter-values";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import ObjectSort from "models/object-sort";
import { ApiResponse, isErrorResponse } from "redux-base/create-api-utils";
import { useAppDispatch } from "redux-base/store";
import { useSessionStorage } from "usehooks-ts";

export interface IDevicesHook {
  confirmDeleteDevice: (device: Device) => void;
  deleteDevice: () => void;
  closeDeleteConfirmationPopup: () => void;
  closeDeleteErrorPopup: () => void;
  openDeviceDetailsPopup: (isOpenedFromOrganisationUnit: boolean) => void;
  closeDeviceDetailsPopup: () => void;
  isDeleteConfirmationPopupOpen: boolean;
  isDeleteErrorPopupOpen: boolean;
  isDeviceDetailsPopupOpen: boolean;
  isDeviceDetailsPopupOpenedFromOrganisationDetailView: boolean;
  deviceFiltersFromSessionStorage: DeviceFilterValues;
  setDeviceFiltersFromSessionStorage: (filters: DeviceFilterValues) => void;
  sortFromSessionStorage: ObjectSort<Device>;
  setSortFromSessionStorage: (sort: ObjectSort<Device>) => void;
  searchBarFromSessionStorage: string;
  setSearchBarFromSessionStorage: (searchBar: string) => void;
}

export const useDevices = (): IDevicesHook => {
  const devicesFilterKey = "devices-filters-storage";
  const sessionStorageSortingKey = "devices-sorting";
  const searchBarSessionStorageKey = "devices-searchbar";

  const dispatch = useAppDispatch();

  const [deviceFiltersFromSessionStorage, setDeviceFiltersFromSessionStorage] =
    useSessionStorage<DeviceFilterValues>(devicesFilterKey, {
      filterValues: [],
      monitoringFilterValues: [],
    });
  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<Device>
  >(sessionStorageSortingKey, { property: "name", isAscending: true });
  const [searchBarFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>(searchBarSessionStorageKey, "");

  const {
    devicesState: {
      openPopup,
      closePopup,
      setIsDeleteConfirmationPopupOpen,
      setIsDeleteErrorPopupOpen,
      setDeleteConfirmationAction,
      deleteConfirmationAction,
      isDeleteConfirmationPopupOpen,
      isDeleteErrorPopupOpen,
      isPopupOpen,
      allowUnlinking,
    },
  } = useDevicesContextProvider();

  const [deleteDeviceMutation] = useDeleteDeviceMutation();

  const confirmDeleteDevice = (device: Device) => {
    if (device.organisationUnitId) {
      setIsDeleteErrorPopupOpen(true);
    } else {
      setIsDeleteConfirmationPopupOpen(true);
      setDeleteConfirmationAction(() => () => deleteDeviceAction(device));
    }
  };

  const closeDeleteConfirmationPopup = () => {
    setIsDeleteConfirmationPopupOpen(false);
  };

  const closeDeleteErrorPopup = () => {
    setIsDeleteErrorPopupOpen(false);
  };

  const deleteDevice = () => deleteConfirmationAction?.();

  const openDeviceDetailsPopup = (isOpenedFromOrganisationUnit: boolean) =>
    openPopup(!isOpenedFromOrganisationUnit);

  const closeDeviceDetailsPopup = () => closePopup();

  const deleteDeviceAction = async (device: Device) => {
    const result = (await deleteDeviceMutation(
      device.id!,
    )) as ApiResponse<void>;

    if (isErrorResponse(result)) {
      dispatch(
        setErrorMessage({
          error: result.error,
        }),
      );
    }

    setIsDeleteConfirmationPopupOpen(false);
    closePopup();
  };

  return {
    confirmDeleteDevice,
    closeDeleteConfirmationPopup,
    closeDeleteErrorPopup,
    deleteDevice,
    openDeviceDetailsPopup,
    closeDeviceDetailsPopup,
    isDeleteConfirmationPopupOpen,
    isDeleteErrorPopupOpen,
    isDeviceDetailsPopupOpen: isPopupOpen,
    isDeviceDetailsPopupOpenedFromOrganisationDetailView: allowUnlinking,
    deviceFiltersFromSessionStorage,
    setDeviceFiltersFromSessionStorage,
    sortFromSessionStorage,
    setSortFromSessionStorage,
    searchBarFromSessionStorage,
    setSearchBarFromSessionStorage,
  };
};
