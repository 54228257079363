import { createApi } from "@reduxjs/toolkit/query/react";
import { ReadConnectorsQuery } from "features/connectors/domain/read-connectors-query";
import { ReadConnectorsResponse } from "features/connectors/domain/read-connectors-response";
import { baseQuery } from "redux-base/create-api-utils";
import { Connector } from "features/connectors/domain/connector";

const baseUrl = "/api/v1/Connector";

export const connectorsApi = createApi({
  reducerPath: "connectors",
  baseQuery,
  tagTypes: ["Connectors"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readConnectors: build.query<ReadConnectorsResponse, ReadConnectorsQuery>({
      query: (body) => ({
        url: `${baseUrl}/ReadConnectors`,
        method: "POST",
        body,
      }),
      providesTags: ["Connectors"],
    }),
    updateConnector: build.mutation<Connector, Connector>({
      query: (body) => ({
        url: `${baseUrl}/UpdateConnector`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Connectors"],
    }),
  }),
});

export const {
  useLazyReadConnectorsQuery,
  useReadConnectorsQuery,
  useUpdateConnectorMutation,
} = connectorsApi;
