import { useUpdateIS32ConfigurationMutation } from "features/systems/IS32/domain/reducers/IS32.reducer";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { useEffect, useState } from "react";
import { useConfirmationPopupContextProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";
import { useTranslation } from "react-i18next";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import UpdateIS32ConfigurationCommand from "features/systems/IS32/domain/models/update-IS32-configuration-command";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { ViewingMode } from "utils/viewing-utils";

export interface IS32DetailsHook {
  viewingMode: ViewingMode;
  cancelIS32ConfigurationMutation: () => void;
  selectedIS32ConfigurationUnit: IS32ConfigurationUnit | undefined;

  submitForm: (fieldValues: FieldValues) => void;
  updateIS32ConfigurationIsLoading: boolean;

  isConfigurationDetailsOpen: boolean;
  openConfigurationDetailsInEditingMode: () => void;

  configurationForm: UseFormReturn<Partial<IS32ConfigurationUnit>, any>;
}

const useIS32DetailsHook = (unit: IS32ConfigurationUnit): IS32DetailsHook => {
  const dispatch = useDispatch();
  const { t } = useTranslation("IS32");

  const { showConfirmationPopup } = useConfirmationPopupContextProvider();
  const { systemsDetailsHook } = useSystemsContextProvider();

  const [
    updateIS32Configuration,
    {
      isLoading: updateIS32ConfigurationIsLoading,
      isSuccess: updateIS32ConfigurationIsSuccess,
      isError: updateIS32ConfigurationIsError,
      error: updateIS32ConfigurationError,
      data: updateIS32ConfigurationData,
    },
  ] = useUpdateIS32ConfigurationMutation();

  const [selectedIS32ConfigurationUnit, setSelectedIS32ConfigurationUnit] =
    useState<IS32ConfigurationUnit>(unit);
  const [isConfigurationDetailsOpen, setIsConfigurationDetailsOpen] =
    useState<boolean>(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");

  const defaultValues: Partial<IS32ConfigurationUnit> = {
    room: selectedIS32ConfigurationUnit?.room ?? "",
    micLevel: selectedIS32ConfigurationUnit?.micLevel ?? null,
    speakerLevel: selectedIS32ConfigurationUnit?.speakerLevel ?? null,
    acousticMonitoringWait30Seconds:
      selectedIS32ConfigurationUnit?.acousticMonitoringWait30Seconds ?? null,
    resetInRoomForToilets:
      selectedIS32ConfigurationUnit?.resetInRoomForToilets ?? null,
    resetInRoomForBeds:
      selectedIS32ConfigurationUnit?.resetInRoomForBeds ?? null,
    onlyResetNoPresenceInToilets:
      selectedIS32ConfigurationUnit?.onlyResetNoPresenceInToilets ?? null,
    onlyResetNoPresenceInRoomBedsAndToilets:
      selectedIS32ConfigurationUnit?.onlyResetNoPresenceInRoomBedsAndToilets ??
      null,
    assistanceToiletByPresenceInRoom:
      selectedIS32ConfigurationUnit?.assistanceToiletByPresenceInRoom ?? null,
    assistanceBedByPresenceInRoom:
      selectedIS32ConfigurationUnit?.assistanceBedByPresenceInRoom ?? null,
    nightFindLightsOffline:
      !selectedIS32ConfigurationUnit?.nightFindLightsOffline,
  };

  const configurationForm = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const {
    reset,
    formState: { isDirty },
  } = configurationForm;

  useEffect(() => {
    resetConfigurationFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIS32ConfigurationUnit]);

  useEffect(() => {
    if (updateIS32ConfigurationIsSuccess && updateIS32ConfigurationData) {
      setSelectedIS32ConfigurationUnit(updateIS32ConfigurationData);
      closeConfigurationDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIS32ConfigurationIsSuccess]);

  useEffect(() => {
    if (updateIS32ConfigurationIsError && updateIS32ConfigurationError) {
      dispatch(
        setErrorMessage({
          error: updateIS32ConfigurationError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIS32ConfigurationIsError]);

  const resetConfigurationFormValues = () => {
    reset({
      room: selectedIS32ConfigurationUnit?.room ?? "",
      micLevel: selectedIS32ConfigurationUnit?.micLevel ?? null,
      speakerLevel: selectedIS32ConfigurationUnit?.speakerLevel ?? null,
      acousticMonitoringWait30Seconds:
        selectedIS32ConfigurationUnit?.acousticMonitoringWait30Seconds ?? null,
      resetInRoomForToilets:
        selectedIS32ConfigurationUnit?.resetInRoomForToilets ?? null,
      resetInRoomForBeds:
        selectedIS32ConfigurationUnit?.resetInRoomForBeds ?? null,
      onlyResetNoPresenceInToilets:
        selectedIS32ConfigurationUnit?.onlyResetNoPresenceInToilets ?? null,
      onlyResetNoPresenceInRoomBedsAndToilets:
        selectedIS32ConfigurationUnit?.onlyResetNoPresenceInRoomBedsAndToilets ??
        null,
      assistanceToiletByPresenceInRoom:
        selectedIS32ConfigurationUnit?.assistanceToiletByPresenceInRoom ?? null,
      assistanceBedByPresenceInRoom:
        selectedIS32ConfigurationUnit?.assistanceBedByPresenceInRoom ?? null,
      nightFindLightsOffline:
        selectedIS32ConfigurationUnit?.nightFindLightsOffline !== undefined &&
        selectedIS32ConfigurationUnit?.nightFindLightsOffline !== null
          ? !selectedIS32ConfigurationUnit?.nightFindLightsOffline
          : null,
    });
  };

  const submitForm = (fieldValues: FieldValues) => {
    if (!selectedIS32ConfigurationUnit) return;

    const configuration: UpdateIS32ConfigurationCommand = {
      address: selectedIS32ConfigurationUnit.address,
      serial: selectedIS32ConfigurationUnit.serial,
      type: selectedIS32ConfigurationUnit.type,
      room: fieldValues.room.trim(),
      micLevel: fieldValues.micLevel,
      speakerLevel: fieldValues.speakerLevel,
      acousticMonitoringWait30Seconds:
        fieldValues.acousticMonitoringWait30Seconds,
      resetInRoomForToilets: fieldValues.resetInRoomForToilets,
      resetInRoomForBeds: fieldValues.resetInRoomForBeds,
      onlyResetNoPresenceInToilets: fieldValues.onlyResetNoPresenceInToilets,
      onlyResetNoPresenceInRoomBedsAndToilets:
        fieldValues.onlyResetNoPresenceInRoomBedsAndToilets,
      assistanceToiletByPresenceInRoom:
        fieldValues.assistanceToiletByPresenceInRoom,
      assistanceBedByPresenceInRoom: fieldValues.assistanceBedByPresenceInRoom,
      nightFindLightsOffline:
        fieldValues.nightFindLightsOffline !== undefined &&
        fieldValues.nightFindLightsOffline !== null
          ? !fieldValues.nightFindLightsOffline
          : null,
      localBusUnits: selectedIS32ConfigurationUnit.localBusUnits,
      systemId: systemsDetailsHook.selectedSystem?.id ?? "",
    };

    updateIS32Configuration(configuration);
  };

  const openConfigurationDetailsInEditingMode = () => {
    setViewingMode("editing");
    setIsConfigurationDetailsOpen(true);
    resetConfigurationFormValues();
  };

  const cancelIS32ConfigurationMutation = () => {
    closeConfigurationDetails(isDirty);
  };

  const closeConfigurationDetails = (isFormStateDirty: boolean) => {
    if (!isFormStateDirty) {
      closeDetailsPopup();
      return;
    }

    showConfirmationPopup(
      () => closeDetailsPopup(),
      t("unsavedChangesConfirmationPopup.title"),
      t("unsavedChangesConfirmationPopup.body"),
      t("unsavedChangesConfirmationPopup.primaryButtonText"),
      t("unsavedChangesConfirmationPopup.secondaryButtonText"),
    );
  };

  const closeDetailsPopup = () => {
    resetConfigurationFormValues();
    setViewingMode("viewing");
    setIsConfigurationDetailsOpen(false);
  };

  return {
    viewingMode,
    cancelIS32ConfigurationMutation,
    selectedIS32ConfigurationUnit,

    submitForm,
    updateIS32ConfigurationIsLoading,

    isConfigurationDetailsOpen,
    openConfigurationDetailsInEditingMode,
    configurationForm,
  };
};

export default useIS32DetailsHook;
