import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import styles from "features/systems/IS32/views/IS32-configuration.module.scss";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { DeviceDetailsProvider } from "features/device/device-details/providers/device-details-provider/device-details.provider";
import DeviceDetailsPopup from "features/device/device-details/views/device-details-popup.component";
import { useIS32ContextProvider } from "features/systems/IS32/context/IS32-provider";
import IS32ConfigurationAccordionItem from "./IS32-configuration-accordion-item";
import { IS32DetailsProvider } from "features/systems/IS32/providers/IS32-details.provider";

const IS32Configuration = (): JSX.Element => {
  const { systemsDetailsHook } = useSystemsContextProvider();
  const { readIS32ConfigurationIsLoading, readIS32ConfigurationData } =
    useIS32ContextProvider();

  return (
    <div className={styles.configurationContainer}>
      {readIS32ConfigurationIsLoading && <LoadingIndicator />}
      {!readIS32ConfigurationIsLoading &&
        readIS32ConfigurationData?.units &&
        [...(readIS32ConfigurationData?.units ?? [])]
          .sort((unitA, unitB) => Number(unitA.address) - Number(unitB.address))
          .map((unit) => {
            return (
              <IS32DetailsProvider key={unit.address} unit={unit}>
                <IS32ConfigurationAccordionItem unit={unit} />
              </IS32DetailsProvider>
            );
          })}
      {systemsDetailsHook.selectedDeviceId && (
        <DeviceDetailsProvider
          deviceId={systemsDetailsHook.selectedDeviceId}
          initialViewingMode={"viewing"}
        >
          <DeviceDetailsPopup />
        </DeviceDetailsProvider>
      )}
    </div>
  );
};

export default IS32Configuration;
