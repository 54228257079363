import { ConfirmationPopupProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";
import AppInformation from "features/app-information/views/app-information.component";
import LoginCallbackView from "features/authentication/views/login-callback.view";
import LoginView from "features/authentication/views/login.view";
import LogoutCallbackView from "features/authentication/views/logout-callback.view";
import { ConnectorsProvider } from "features/connectors/context/connectors-provider";
import Connectors from "features/connectors/views/connectors";
import PowerBIReport from "features/dashboard/domain/models/powerBIReport";
import Dashboard from "features/dashboard/views/dashboard";
import EmbeddedReport from "features/dashboard/views/embedded-report/embedded-report";
import { DevicesProvider } from "features/device/devices/providers/devices-provider/devices.provider";
import Devices from "features/device/devices/views/devices.component";
import { SendTestEventProvider } from "features/device/send-test-event/context/send-test-event-provider";
import { EmergencyAlarmSettingsProvider } from "features/emergency-alarm-settings/context/emergency-alarm-settings-provider";
import EmergencyAlarmSettings from "features/emergency-alarm-settings/views/emergency-alarm-settings";
import { ExplanationLinksProvider } from "features/explanation-links/context/explanation-links-provider";
import ExplanationLinks from "features/explanation-links/views/explanation-links";
import { GatewaysProvider } from "features/gateway/context/gateway-provider";
import Gateways from "features/gateway/views/gateways";
import { HistoryProvider } from "features/history/providers/history-provider";
import History from "features/history/views/history.component";
import Integrations from "features/integrations/views/integrations";
import { MalfunctionNotificationSettingsProvider } from "features/malfunction-notification-settings/context/malfunction-notification-settings-provider";
import MalfunctionNotificationSettings from "features/malfunction-notification-settings/views/malfunction-notification-settings";
import { OrganisationUnitsProvider } from "features/organisation/context/organisation-units-provider";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import Organisation from "features/organisation/views/organisation";
import { ResidentSettingsProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import ResidentsSettings from "features/residents/residents-settings/views/residents-settings";
import Roles from "features/role/views/roles";
import AuthorisedRoute from "features/routing/authorised-route";
import RedirectToDashboardDetails from "features/routing/redirects/redirect-to-dashboard-detail";
import RedirectToIntegrationDetails from "features/routing/redirects/redirect-to-integration-details";
import RedirectToRoot from "features/routing/redirects/redirect-to-root";
import routes from "features/routing/routes";
import Settings from "features/settings/views/settings";
import { SystemsProvider } from "features/systems/context/systems-provider";
import Systems from "features/systems/views/systems";
import { TemporaryAccessProvider } from "features/temporary-access/providers/temporary-access-provider";
import TemporaryAccess from "features/temporary-access/views/temporary-access.component";
import { TenantsProvider } from "features/tenants/providers/tenants-provider";
import Tenants from "features/tenants/views/tenants";
import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import LayoutWithAuthentication from "./layout-with-authentication";
import RedirectToSettingsDetails from "./redirects/redirect-to-settings-details";

function Routing(): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<LayoutWithAuthentication />}>
        <Route
          path={routes.tenants.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={routes.tenants.atLeastOnePermissionOf}
              showForTenantManagerOnly={routes.tenants.showForTenantManagerOnly}
            >
              <TenantsProvider>
                <Tenants />
              </TenantsProvider>
            </AuthorisedRoute>
          }
        />
        <Route
          path={routes.organisation.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={
                routes.organisation.atLeastOnePermissionOf
              }
            >
              <OrganisationUnitsProvider>
                <Organisation />
              </OrganisationUnitsProvider>
            </AuthorisedRoute>
          }
        />
        <Route
          path={routes.integrations.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={
                routes.integrations.atLeastOnePermissionOf
              }
            >
              <OrganisationUnitTreeProvider.All>
                <Integrations />
              </OrganisationUnitTreeProvider.All>
            </AuthorisedRoute>
          }
        >
          <Route
            path={routes.devices.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={routes.devices.atLeastOnePermissionOf}
              >
                <DevicesProvider>
                  <SendTestEventProvider>
                    <Devices />
                  </SendTestEventProvider>
                </DevicesProvider>
              </AuthorisedRoute>
            }
          />
          <Route
            path={routes.systems.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={routes.systems.atLeastOnePermissionOf}
              >
                <ConfirmationPopupProvider>
                  <DevicesProvider>
                    <SystemsProvider>
                      <Systems />
                    </SystemsProvider>
                  </DevicesProvider>
                </ConfirmationPopupProvider>
              </AuthorisedRoute>
            }
          />
          <Route
            path={routes.gateways.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={routes.gateways.atLeastOnePermissionOf}
              >
                <GatewaysProvider>
                  <Gateways />
                </GatewaysProvider>
              </AuthorisedRoute>
            }
          />

          <Route
            path={routes.connectors.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={
                  routes.connectors.atLeastOnePermissionOf
                }
              >
                <ConnectorsProvider>
                  <Connectors />
                </ConnectorsProvider>
              </AuthorisedRoute>
            }
          />
          <Route
            path={`${routes.integrations.path}`}
            element={<RedirectToIntegrationDetails />}
          />
        </Route>
        <Route
          path={routes.roles.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={routes.roles.atLeastOnePermissionOf}
            >
              <Roles />
            </AuthorisedRoute>
          }
        />
        <Route
          path={routes.residentsSettings.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={
                routes.residentsSettings.atLeastOnePermissionOf
              }
            >
              <ConfirmationPopupProvider>
                <ResidentSettingsProvider>
                  <ResidentsSettings />
                </ResidentSettingsProvider>
              </ConfirmationPopupProvider>
            </AuthorisedRoute>
          }
        />
        <Route
          path={routes.temporaryAccess.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={
                routes.temporaryAccess.atLeastOnePermissionOf
              }
            >
              <TemporaryAccessProvider>
                <TemporaryAccess />
              </TemporaryAccessProvider>
            </AuthorisedRoute>
          }
        />
        <Route
          path={routes.history.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={routes.history.atLeastOnePermissionOf}
            >
              <HistoryProvider>
                <History />
              </HistoryProvider>
            </AuthorisedRoute>
          }
        />
        <Route
          path={routes.settings.path}
          element={
            <AuthorisedRoute
              atLeastOnePermissionOf={routes.settings.atLeastOnePermissionOf}
            >
              <Settings />
            </AuthorisedRoute>
          }
        >
          <Route
            path={routes.settingsExplanationLink.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={
                  routes.settingsExplanationLink.atLeastOnePermissionOf
                }
              >
                <ExplanationLinksProvider>
                  <ExplanationLinks />
                </ExplanationLinksProvider>
              </AuthorisedRoute>
            }
          />
          <Route
            path={routes.settingsEmergencyAlarm.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={
                  routes.settingsEmergencyAlarm.atLeastOnePermissionOf
                }
              >
                <ConfirmationPopupProvider>
                  <EmergencyAlarmSettingsProvider>
                    <EmergencyAlarmSettings />
                  </EmergencyAlarmSettingsProvider>
                </ConfirmationPopupProvider>
              </AuthorisedRoute>
            }
          />
          <Route
            path={routes.settingsMalfunctionNotifications.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={
                  routes.settingsMalfunctionNotifications.atLeastOnePermissionOf
                }
              >
                <MalfunctionNotificationSettingsProvider>
                  <MalfunctionNotificationSettings />
                </MalfunctionNotificationSettingsProvider>
              </AuthorisedRoute>
            }
          />
          <Route
            path={`${routes.settings.path}`}
            element={<RedirectToSettingsDetails />}
          />
        </Route>
        <Route path={routes.dashboard.path} element={<Dashboard />}>
          <Route
            path={routes.dashboardAlarms.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={
                  routes.dashboardAlarms.atLeastOnePermissionOf
                }
              >
                <EmbeddedReport
                  reportName={PowerBIReport.Alarms}
                  key={PowerBIReport.Alarms}
                />
              </AuthorisedRoute>
            }
          />
          <Route
            path={routes.dashboardResidents.path}
            element={
              <AuthorisedRoute
                atLeastOnePermissionOf={
                  routes.dashboardResidents.atLeastOnePermissionOf
                }
              >
                <EmbeddedReport
                  reportName={PowerBIReport.Residents}
                  key={PowerBIReport.Residents}
                />
              </AuthorisedRoute>
            }
          />
          <Route
            path={`${routes.dashboard.path}`}
            element={<RedirectToDashboardDetails />}
          />
        </Route>
        <Route path={routes.appInformation.path} element={<AppInformation />} />
        <Route path="" element={<RedirectToRoot />} />
        <Route path="*" element={<RedirectToRoot />} />
      </Route>
      <Route path="/login" element={<LoginView />} />
      <Route path="/login-callback" element={<LoginCallbackView />} />
      <Route path="/logout-callback" element={<LogoutCallbackView />} />
    </Routes>
  );
}

export default Routing;
