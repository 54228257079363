import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./acoustic-scenario-configuration.component.module.scss";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import TimeFrame from "features/residents/residents-settings/domain/models/configurations/time-frame";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import TimeFrames from "features/residents/residents-settings/views/resident-scenario-row/time-frames.component";
import AcousticConfiguration from "features/residents/residents-settings/domain/models/configurations/acoustic-configuration";
import { TimePicker, TimeValidationError } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";
import { SonevoSlider } from "components/slider/sonevo-slider.component";
import { AccessTimeRounded, MicNoneOutlined } from "@mui/icons-material";

enum ActiveOption {
  AlarmsOnly = 0,
}

interface IProps {
  organisationUnitScenario: OrganisationUnitScenario;
  scenarioTitle: string;
  index: number;
}

export function AcousticScenarioConfiguration(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const [configuration, setConfiguration] = useState(
    jsonParseWithLowerCaseKeys(
      props.organisationUnitScenario.configuration,
    ) as AcousticConfiguration,
  );

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const activeOptions = [
    <MenuItem
      data-testid={`alarm-active`}
      key={`alarm-active`}
      value={ActiveOption.AlarmsOnly}
    >
      {t("scenarioConfiguration.alarmActive")}
    </MenuItem>,
  ];

  useEffect(() => {
    residentDetailsHook.clearValidationErrorForScenario(props.index);
    setConfiguration(
      jsonParseWithLowerCaseKeys(
        props.organisationUnitScenario.configuration,
      ) as AcousticConfiguration,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organisationUnitScenario, residentDetailsHook.viewingMode]);

  function timeFrameValuesChanged(
    newTimeFrames: TimeFrame[] | null,
    newActiveAllDay: boolean,
  ): void {
    const newConfiguration = {
      ...configuration,
      timeFrames: newTimeFrames,
      sendAlarmAllDay: newActiveAllDay,
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  function soundThresholdChanged(
    event: Event,
    value: number | number[],
    activeThumb: number,
  ): void {
    const newConfiguration = {
      ...configuration,
      soundThreshold: value as number,
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  function durationChanged(
    event: Event,
    value: number | number[],
    activeThumb: number,
  ): void {
    const newConfiguration = {
      ...configuration,
      duration: value as number,
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }
  function temporaryDurationChanged(
    event: Event,
    value: number | number[],
    activeThumb: number,
  ): void {
    const newConfiguration = {
      ...configuration,
      temporaryConfiguration: {
        ...configuration.temporaryConfiguration!,
        duration: value as number,
      },
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  function temporarySoundThresholdChanged(
    event: Event,
    value: number | number[],
    activeThumb: number,
  ): void {
    const newConfiguration = {
      ...configuration,
      temporaryConfiguration: {
        ...configuration.temporaryConfiguration!,
        soundThreshold: value as number,
      },
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  function temporaryConfigurationEnabledChanged(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void {
    const newConfiguration = {
      ...configuration,
      temporaryConfigurationEnabled: checked,
      temporaryConfiguration: checked
        ? {
            validUntil: moment()
              .add(moment.duration(1, "hours"))
              .minutes(0)
              .toISOString(),
            soundThreshold: configuration.soundThreshold,
            duration: configuration.duration,
          }
        : null,
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  function onTemporaryValidUntilChanged(
    value: Moment | null,
    context: PickerChangeHandlerContext<TimeValidationError>,
  ): void {
    if (!value) {
      return;
    }

    const newConfiguration = {
      ...configuration,

      temporaryConfiguration: {
        ...configuration.temporaryConfiguration!,
        validUntil: value?.isBefore(moment())
          ? moment()
              .add(1, "day")
              .hour(value.hour())
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString()
          : moment()
              .hour(value.hour())
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString(),
      },
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  const isEditTemporaryConfigurationDisabled =
    residentDetailsHook.viewingMode === "viewing";
  const temporaryConfigurationDisabled =
    residentDetailsHook.viewingMode === "viewing" ||
    configuration.temporaryConfigurationEnabled;

  return (
    <div className={styles.acousticScenarioConfigurationContainer}>
      <div className={styles.restoreRow}>
        <Typography variant="subtitle1">
          {`${props.scenarioTitle} ${t(
            `scenarioConfiguration.scenarioDescription`,
          )}`}
        </Typography>
      </div>
      <div className={styles.alarmActiveRow}>
        <FormControl>
          <InputLabel id={styles.alarmActiveLabel}>
            {t("scenarioConfiguration.alarmActiveLabel")}
          </InputLabel>
          <Select
            inputProps={{ "data-testid": "AlarmActiveInput" }}
            id="alarmActiveInput"
            variant="outlined"
            className={styles.alarmActiveSelect}
            labelId={styles.alarmActiveLabel}
            label={t("scenarioConfiguration.alarmActiveLabel")}
            value={ActiveOption.AlarmsOnly}
            defaultValue={0}
            disabled={true}
          >
            {activeOptions.map((menuItem) => menuItem)}
          </Select>
        </FormControl>
      </div>

      <TimeFrames
        activeAllDay={configuration.sendAlarmAllDay}
        index={props.index}
        timeFrames={configuration.timeFrames}
        valuesChanged={timeFrameValuesChanged}
        timeFramesDescription={t(
          "scenarioConfiguration.timeFramesDescriptionAlarm",
        )}
      />
      <div className={styles.acousticSettings}>
        <SonevoSlider
          label={t("acousticScenarioConfiguration.soundThreshold")}
          value={configuration.soundThreshold}
          disabled={temporaryConfigurationDisabled}
          onChange={soundThresholdChanged}
          sliderIconStart={
            <MicNoneOutlined
              className={styles.sliderIconSmall}
              color={temporaryConfigurationDisabled ? "disabled" : "primary"}
            />
          }
          sliderIconEnd={
            <MicNoneOutlined
              className={styles.sliderIcon}
              color={temporaryConfigurationDisabled ? "disabled" : "primary"}
            />
          }
        />
        <SonevoSlider
          label={t("acousticScenarioConfiguration.duration")}
          value={configuration.duration}
          disabled={temporaryConfigurationDisabled}
          onChange={durationChanged}
          sliderIconStart={
            <AccessTimeRounded
              className={styles.sliderIconSmall}
              color={temporaryConfigurationDisabled ? "disabled" : "primary"}
            />
          }
          sliderIconEnd={
            <AccessTimeRounded
              className={styles.sliderIcon}
              color={temporaryConfigurationDisabled ? "disabled" : "primary"}
            />
          }
        />

        <FormControl className={styles.temporaryConfigurationEnabledControl}>
          <Typography variant="subtitle1">
            {t("acousticScenarioConfiguration.temporaryConfigurationEnabled")}
          </Typography>
          <Checkbox
            checked={configuration.temporaryConfigurationEnabled}
            disabled={isEditTemporaryConfigurationDisabled}
            onChange={temporaryConfigurationEnabledChanged}
          />
        </FormControl>

        {configuration.temporaryConfigurationEnabled && (
          <>
            <Typography variant="subtitle1">
              {t("acousticScenarioConfiguration.validUntil")}
            </Typography>
            <TimePicker
              label={t("scenarioConfiguration.to")}
              ampm={false}
              view={"hours"}
              views={["hours"]}
              format="HH:mm"
              value={moment(configuration.temporaryConfiguration?.validUntil)}
              onChange={onTemporaryValidUntilChanged}
              disabled={isEditTemporaryConfigurationDisabled}
              slotProps={{
                popper: {
                  className: "time-picker-popper",
                },
              }}
            />
            <SonevoSlider
              label={t("acousticScenarioConfiguration.temporarySoundThreshold")}
              value={configuration.temporaryConfiguration?.soundThreshold}
              disabled={isEditTemporaryConfigurationDisabled}
              onChange={temporarySoundThresholdChanged}
              sliderIconStart={
                <MicNoneOutlined
                  className={styles.sliderIconSmall}
                  color={
                    isEditTemporaryConfigurationDisabled
                      ? "disabled"
                      : "primary"
                  }
                />
              }
              sliderIconEnd={
                <MicNoneOutlined
                  className={styles.sliderIcon}
                  color={
                    isEditTemporaryConfigurationDisabled
                      ? "disabled"
                      : "primary"
                  }
                />
              }
            />
            <SonevoSlider
              label={t("acousticScenarioConfiguration.temporaryDuration")}
              value={configuration.temporaryConfiguration?.duration}
              disabled={isEditTemporaryConfigurationDisabled}
              onChange={temporaryDurationChanged}
              sliderIconStart={
                <AccessTimeRounded
                  className={styles.sliderIconSmall}
                  color={
                    isEditTemporaryConfigurationDisabled
                      ? "disabled"
                      : "primary"
                  }
                />
              }
              sliderIconEnd={
                <AccessTimeRounded
                  className={styles.sliderIcon}
                  color={
                    isEditTemporaryConfigurationDisabled
                      ? "disabled"
                      : "primary"
                  }
                />
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

export default AcousticScenarioConfiguration;
