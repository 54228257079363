import { ReactElement } from "react";
import { Typography } from "@mui/material";
import styles from "components/location/location-row.module.scss";
import OfficeBuildingMarker from "components/custom-icons/office-building-marker";
import Constants from "style/constants";

interface IProps {
  name: string;
}

const LocationRow = (props: Readonly<IProps>): ReactElement => {
  return (
    <div className={styles.locationContainer}>
      <div className={styles.locationIconContainer}>
        <OfficeBuildingMarker
          sx={{
            fontSize: 16,
            color: Constants.Colors.onSurface,
          }}
        />
      </div>
      <Typography sx={{ paddingLeft: "8px" }} variant="subtitle1">
        {props.name}
      </Typography>
    </div>
  );
};

export default LocationRow;
