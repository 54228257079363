import { TextField, Typography } from "@mui/material";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";
import styles from "features/connectors/views/connector-details/connector-information.module.scss";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ConnectorInformation = (): JSX.Element => {
  const { t } = useTranslation("connectors");

  const { connectorDetailsHook } = useConnectorsContextProvider();

  const { control } = useFormContext();

  const isViewing = connectorDetailsHook.viewingMode === "viewing";
  const isEditing = connectorDetailsHook.viewingMode === "editing";

  const selectedConnector = connectorDetailsHook.currentSelectedConnector;

  const textField = (name: string, label: string, multiline = false) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          inputProps={{ "data-testid": `${name}Input`, maxLength: 255 }}
          id={`${name}Input`}
          label={label}
          variant="outlined"
          multiline={multiline}
          minRows={multiline ? 8 : undefined}
          maxRows={multiline ? 12 : undefined}
          fullWidth
        />
      )}
    />
  );

  const readOnlyField = (
    label: string,
    value: string,
    respectTextFormat = false,
  ) => (
    <div className={styles.informationItem}>
      <Typography variant="h5">{label}</Typography>
      <Typography
        variant="subtitle1"
        className={`${respectTextFormat && styles.textFormat}`}
      >
        {value}
      </Typography>
    </div>
  );

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      <Typography variant="h2" data-testid="connectorInformationName">
        {isViewing ? selectedConnector?.name : t("details.editConnectorTitle")}
      </Typography>

      {selectedConnector?.type &&
        readOnlyField(
          t("details.information.typeLabel"),
          t(`details.information.type.${selectedConnector?.type}`),
        )}

      {selectedConnector?.certificateExpiryDate && (
        <div className={styles.informationItem}>
          <Typography variant="h5">
            {t("details.information.certificateExpiryDate")}
          </Typography>
          <Typography variant="subtitle1">
            {moment(selectedConnector.certificateExpiryDate)
              .format("DD-MM-YYYY")
              .toString()}
          </Typography>
        </div>
      )}

      {isEditing && textField("note", t("details.information.noteLabel"), true)}

      {isViewing &&
        selectedConnector?.note &&
        readOnlyField(
          t("details.information.noteLabel"),
          selectedConnector.note,
          true,
        )}
    </div>
  );
};

export default ConnectorInformation;
