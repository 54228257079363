import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import AcousticConfiguration from "features/residents/residents-settings/domain/models/configurations/acoustic-configuration";
import TimeFrame from "features/residents/residents-settings/domain/models/configurations/time-frame";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import styles from "features/residents/residents-settings/views/resident-scenario-row/alarm-only-scenario-configuration.module.scss";
import TimeFrames from "features/residents/residents-settings/views/resident-scenario-row/time-frames.component";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import BaseAlarmOnlyScenarioConfiguration from "features/residents/residents-settings/domain/models/configurations/base-alarm-only-scenario-configuration";

enum ActiveOption {
  AlarmsOnly = 0,
}

interface Props {
  organisationUnitScenario: OrganisationUnitScenario;
  scenarioTitle: string;
  index: number;
}

const AlarmOnlyScenarioConfiguration = ({
  organisationUnitScenario,
  scenarioTitle,
  index,
}: Readonly<Props>): JSX.Element => {
  const { t } = useTranslation("residentsSettings");

  const [configuration, setConfiguration] = useState(
    jsonParseWithLowerCaseKeys(
      organisationUnitScenario.configuration,
    ) as BaseAlarmOnlyScenarioConfiguration,
  );

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const activeOptions = [
    <MenuItem
      data-testid={`alarm-active`}
      key={`alarm-active`}
      value={ActiveOption.AlarmsOnly}
    >
      {t("scenarioConfiguration.alarmActive")}
    </MenuItem>,
  ];

  useEffect(() => {
    residentDetailsHook.clearValidationErrorForScenario(index);
    setConfiguration(
      jsonParseWithLowerCaseKeys(
        organisationUnitScenario.configuration,
      ) as AcousticConfiguration,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitScenario, residentDetailsHook.viewingMode]);

  const timeFrameValuesChanged = (
    newTimeFrames: TimeFrame[] | null,
    newActiveAllDay: boolean,
  ): void => {
    const newConfiguration = {
      ...configuration,
      timeFrames: newTimeFrames,
      sendAlarmAllDay: newActiveAllDay,
    };
    residentDetailsHook.scenarioConfigurationUpdated(newConfiguration, index);
    setConfiguration(newConfiguration);
  };

  return (
    <div className={styles.container}>
      <div className={styles.restoreRow}>
        <Typography variant="subtitle1">
          {`${scenarioTitle} ${t(`scenarioConfiguration.scenarioDescription`)}`}
        </Typography>
      </div>
      <div className={styles.alarmActiveRow}>
        <FormControl>
          <InputLabel id="alarm-active-label">
            {t("scenarioConfiguration.alarmActiveLabel")}
          </InputLabel>
          <Select
            inputProps={{ "data-testid": "AlarmActiveInput" }}
            id="alarmActiveInput"
            variant="outlined"
            className={styles.alarmActiveSelect}
            labelId="alarm-active-label"
            label={t("scenarioConfiguration.alarmActiveLabel")}
            value={ActiveOption.AlarmsOnly}
            defaultValue={0}
            disabled={true}
          >
            {activeOptions.map((menuItem) => menuItem)}
          </Select>
        </FormControl>
      </div>

      <TimeFrames
        activeAllDay={configuration.sendAlarmAllDay}
        index={index}
        timeFrames={configuration.timeFrames}
        valuesChanged={timeFrameValuesChanged}
        timeFramesDescription={t(
          "scenarioConfiguration.timeFramesDescriptionAlarm",
        )}
      />
    </div>
  );
};

export default AlarmOnlyScenarioConfiguration;
