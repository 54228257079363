import {
  DesktopWindowsOutlined,
  HearingOutlined,
  LocalFireDepartmentOutlined,
  MedicalServicesOutlined,
  NotificationsActiveOutlined,
  NotificationsNoneOutlined,
  SecurityRounded,
} from "@mui/icons-material";
import ConnectionIcon from "components/custom-icons/connection-icon";
import Custom1Icon from "components/custom-icons/custom1-icon";
import Custom2Icon from "components/custom-icons/custom2-icon";
import Custom3Icon from "components/custom-icons/custom3-icon";
import DoorOpenOutlineRoundedIcon from "components/custom-icons/door-open-outline-rounded-icon";
import Doorbell3POutlineIcon from "components/custom-icons/doorbell-3p-outline-icon";
import EmergencyHomeRounded from "components/custom-icons/emergency-home-rounded";
import GearIcon from "components/custom-icons/gear-icon";
import { InBathroomIcon } from "components/custom-icons/in-bathroom-icon";
import IntentionOutOfBedIcon from "components/custom-icons/intention-out-of-bed-icon";
import IntruderIcon from "components/custom-icons/intruder-icon";
import MotionDetectedIcon from "components/custom-icons/motion-detected-icon";
import OutOfBedIcon from "components/custom-icons/out-of-bed-icon";
import { OutOfRoomIcon } from "components/custom-icons/out-of-room-icon";
import SolidPeopleRobberyIcon from "components/custom-icons/solid-people-robbery-icon";
import SolidPersonFallingIcon from "components/custom-icons/solid-person-falling-icon";
import SolidPersonWalkingDashedLineArrowRightIcon from "components/custom-icons/solid-person-walking-dashed-line-arrow-right-icon";
import SupervisorAccountOutlinedIcon from "components/custom-icons/supervisor-account-outlined-icon";
import { AlarmType } from "features/history/domain/models/alarm-type";
import { ReactElement } from "react";
import Constants from "style/constants";

interface IProps {
  type: string;
}

function AlarmIcon(props: Readonly<IProps>): ReactElement {
  function getIcon() {
    switch (props.type) {
      case AlarmType.Acoustic:
        return <HearingOutlined />;
      case AlarmType.Call:
      case AlarmType.ToiletCall:
      case AlarmType.WirelessCall:
      case AlarmType.GenericCall:
        return <NotificationsNoneOutlined />;
      case AlarmType.Assistance:
        return <SupervisorAccountOutlinedIcon />;
      case AlarmType.Doctor:
        return <MedicalServicesOutlined />;
      case AlarmType.Door:
      case AlarmType.DoorOpen:
        return <DoorOpenOutlineRoundedIcon />;
      case AlarmType.Doorbell:
        return <Doorbell3POutlineIcon />;
      case AlarmType.Emergency:
        return <EmergencyHomeRounded />;
      case AlarmType.EmergencyCall:
        return <NotificationsActiveOutlined />;
      case AlarmType.ExternalSystem:
        return <DesktopWindowsOutlined />;
      case AlarmType.IntruderDetected:
        return <IntruderIcon color={Constants.Colors.onSurface} />;
      case AlarmType.Fall:
        return <SolidPersonFallingIcon color={Constants.Colors.onSurface} />;
      case AlarmType.Fire:
        return <LocalFireDepartmentOutlined />;
      case AlarmType.Medical:
        return <Custom1Icon color={Constants.Colors.onSurface} />;
      case AlarmType.Movement:
        return <MotionDetectedIcon />;
      case AlarmType.IntentionOutOfBed:
        return <IntentionOutOfBedIcon color={Constants.Colors.onSurface} />;
      case AlarmType.OutOfBed:
        return <OutOfBedIcon color={Constants.Colors.onSurface} />;
      case AlarmType.OutOfRoom:
        return <OutOfRoomIcon color={Constants.Colors.onSurface} />;
      case AlarmType.InBathroom:
        return <InBathroomIcon color={Constants.Colors.onSurface} />;
      case AlarmType.PersonalSecurity:
        return <SecurityRounded />;
      case AlarmType.PullOut:
        return <ConnectionIcon />;
      case AlarmType.Resuscitation:
        return <Custom2Icon />;
      case AlarmType.Robbery:
        return <SolidPeopleRobberyIcon />;
      case AlarmType.ServiceRequest:
        return <Custom3Icon />;
      case AlarmType.Technical:
        return <GearIcon color={Constants.Colors.onSurface} />;
      case AlarmType.WanderingDetection:
        return <SolidPersonWalkingDashedLineArrowRightIcon />;
      case AlarmType.EmergencyAlarm:
        return <SecurityRounded />;
      default:
        return <></>;
    }
  }

  return getIcon();
}

export default AlarmIcon;
