import Gateway from "features/gateway/domain/models/gateway";
import SystemDevice from "features/systems/domain/models/system-device";

export interface System {
  id?: string;
  type: SystemType;
  name: string;
  note?: string;
  macAddress?: string;
  audioAvailable?: boolean;
  lastCommunicationDate?: Date;
  organisationUnitId?: string;
  gateway: Gateway;
  lastSystemEvent: SystemEvent | undefined;
  devices?: SystemDevice[];
}

export interface SystemEvent {
  systemEventType: SystemEventType;
  systemEventStatus: SystemEventStatus;
  response: string;
  lastStatusChangeOn: Date;
}

export enum SystemEventType {
  Unknown = "Unknown",
  ScanIS32Configuration = "ScanIS32Configuration",
}

export enum SystemEventStatus {
  InProgress = "InProgress",
  Succeeded = "Succeeded",
  Failed = "Failed",
}

export enum SystemType {
  Unknown = "",
  IS32 = "IS32",
}
