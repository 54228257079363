import { useTranslation } from "react-i18next";
import styles from "features/connectors/views/connector-details/connector-settings/connector-settings-organisation.module.scss";
import { Button } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import LocationsModal from "./locations-modal/locations-modal.module";
import LocationRow from "../../../../../components/location/location-row.component";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";

const ConnectorSettingsOrganisation = (): JSX.Element => {
  const { t } = useTranslation("connectors");

  const { connectorDetailsHook } = useConnectorsContextProvider();
  const isEditing = connectorDetailsHook.viewingMode === "editing";

  return (
    <div className={`${styles.container}`}>
      {connectorDetailsHook.form.getValues("organisationUnits")?.map((x) => (
        <LocationRow name={x.name} key={x.id} />
      ))}
      {isEditing && (
        <Button
          variant="outlined"
          onClick={connectorDetailsHook.openLocationPicker}
          startIcon={<EditOutlined />}
          data-testid="editLocationsButton"
        >
          {t("details.settings.editLocationsButton")}
        </Button>
      )}
      {connectorDetailsHook.isLocationPickerOpen && <LocationsModal />}
    </div>
  );
};

export default ConnectorSettingsOrganisation;
