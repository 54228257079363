import { createApi } from "@reduxjs/toolkit/query/react";
import Device from "features/device/domain/models/device";
import ReadDevicesCommand from "features/device/domain/models/read-devices-command";
import ReadDevicesResponse from "features/device/domain/models/read-devices-response";
import ValidateDeviceExternalIdCommand from "features/device/domain/models/validate-device-functional-id-command";
import ValidateDeviceNameCommand from "features/device/domain/models/validate-device-name-command";
import { baseQuery } from "redux-base/create-api-utils";
import CreateDeviceCommand from "features/device/domain/models/create-device-command";
import ReadFilterValuesResponse from "features/device/domain/models/read-filter-values-response";
import UpdateDeviceCommand from "features/device/domain/models/update-device-command";
import ValidateBLEBeaconCommand from "features/device/domain/models/validate-ble-beacon-command";
import ReadFilterValuesCommand from "features/device/domain/models/read-filter-values-command";
import GetLinkableGatewaysResponse from "features/device/domain/models/get-linkable-gateways-response";

const baseUrl = "/api/v1/device";
const baseUrlV2 = "/api/v2/device";

export const deviceApi = createApi({
  reducerPath: "device",
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Devices", "DeviceFilters"],
  endpoints: (build) => ({
    createDevice: build.mutation<Device, CreateDeviceCommand>({
      query: (body) => ({
        url: `${baseUrlV2}/CreateDevice`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Devices", "DeviceFilters"],
    }),

    readDevices: build.query<ReadDevicesResponse, ReadDevicesCommand>({
      query: (command) => ({
        url: `${baseUrlV2}/ReadDevices`,
        method: "POST",
        body: command,
      }),
      providesTags: ["Devices"],
    }),

    readDevice: build.query<Device, string>({
      query: (deviceId) => ({
        url: `${baseUrlV2}/ReadDevice/${deviceId}`,
        method: "GET",
      }),
    }),

    updateDevice: build.mutation<Device, UpdateDeviceCommand>({
      query: (body) => ({
        url: `${baseUrlV2}/UpdateDevice`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Devices", "DeviceFilters"],
    }),

    deleteDevice: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteDevice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Devices", "DeviceFilters"],
    }),

    readFilterValues: build.query<
      ReadFilterValuesResponse,
      ReadFilterValuesCommand | void
    >({
      query: (command) => ({
        url: `${baseUrl}/ReadFilterValues`,
        method: "POST",
        body: command ?? {},
      }),
      providesTags: ["DeviceFilters"],
    }),

    validateDeviceName: build.mutation<void, ValidateDeviceNameCommand>({
      query: (body) => ({
        url: `${baseUrlV2}/ValidateDeviceName`,
        method: "POST",
        body,
      }),
    }),

    validateDeviceExternalId: build.mutation<
      void,
      ValidateDeviceExternalIdCommand
    >({
      query: (body) => ({
        url: `${baseUrlV2}/ValidateDeviceExternalId`,
        method: "POST",
        body,
      }),
    }),

    validateBLEBeacon: build.mutation<void, ValidateBLEBeaconCommand>({
      query: (body) => ({
        url: `${baseUrl}/ValidateBLEBeacon`,
        method: "POST",
        body,
      }),
    }),

    getLinkableGateways: build.query<
      GetLinkableGatewaysResponse,
      string | undefined
    >({
      query: (deviceType) => ({
        url: deviceType
          ? `${baseUrl}/GetLinkableGateways?deviceType=${deviceType}`
          : `${baseUrl}/GetLinkableGateways`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateDeviceMutation,
  useLazyReadDevicesQuery,
  useLazyReadDeviceQuery,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useLazyReadFilterValuesQuery,
  useValidateDeviceNameMutation,
  useValidateDeviceExternalIdMutation,
  useValidateBLEBeaconMutation,
  useGetLinkableGatewaysQuery,
} = deviceApi;
