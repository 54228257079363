import React, { ReactNode } from "react";
import useIS32DetailsHook, {
  IS32DetailsHook,
} from "features/systems/IS32/hooks/IS32-details-hook";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";

const IS32DetailsContext = React.createContext<IS32DetailsHook>({} as never);

interface Props {
  children: ReactNode;
  unit: IS32ConfigurationUnit;
}

export const IS32DetailsProvider = ({
  children,
  unit,
}: Readonly<Props>): JSX.Element => {
  return (
    <IS32DetailsContext.Provider value={useIS32DetailsHook(unit)}>
      {children}
    </IS32DetailsContext.Provider>
  );
};

export const useIS32DetailsContextProvider = (): IS32DetailsHook =>
  React.useContext<IS32DetailsHook>(IS32DetailsContext);
