import OrganisationUnit from "features/organisation/domain/models/organisation-unit";

export interface Connector {
  id?: string;
  type: ConnectorType;
  name: string;
  note?: string;
  lastCommunicationDate?: Date;
  certificateExpiryDate?: Date;
  organisationUnits: OrganisationUnit[];
}

export enum ConnectorType {
  Unknown = "Unknown",
  NedapOns = "NedapOns",
}
