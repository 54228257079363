import {
  CheckRounded,
  Close,
  NotificationsActiveOutlined,
} from "@mui/icons-material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import "features/device/device-details/views/device-details-popup-header.component.scss";
import { useDevicesContextProvider } from "features/device/devices/providers/devices-provider/devices.provider";
import { useSendTestEventContextProvider } from "features/device/send-test-event/context/send-test-event-provider";
import SendTestEventPopup from "features/device/send-test-event/views/send-test-event-popup.component";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import { SystemType } from "features/systems/domain/models/system";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function DeviceDetailsPopupHeader(): ReactElement {
  const { t } = useTranslation("deviceDetails");
  const { openSendTestEventPopup } = useSendTestEventContextProvider();
  const {
    handleClosePopup,
    handleCancelEdit,
    handleSetEditMode,
    handleDelete,
    currentViewingMode,
    isLoading,
    currentDevice,
  } = useDeviceDetailsHook();

  const {
    devicesState: { allowUnlinking },
  } = useDevicesContextProvider();

  const { isSendTestEventPopupOpen } = useSendTestEventContextProvider();

  const isIs32Device = currentDevice?.system?.type === SystemType.IS32;

  const isSendTestEventButtonVisible =
    currentDevice?.organisationUnitId &&
    (currentDevice?.gateway?.type === GatewayType.IoTEdge ||
      currentDevice?.gateway?.type === GatewayType.IoTEdgeCluster ||
      isIs32Device);

  const isCreating = currentViewingMode === "creation";
  const isEditing = currentViewingMode === "editing";

  return (
    <div className="device-details-header-container">
      {(isCreating || isEditing) && (
        <Button
          data-testid="deviceDetailCancelButton"
          variant="text"
          onClick={isCreating ? handleClosePopup : handleCancelEdit}
          disabled={isLoading}
        >
          {t("cancelFormButton")}
        </Button>
      )}
      {(currentViewingMode === "creation" ||
        currentViewingMode === "editing") && (
        <LoadingButton
          data-testid="deviceDetailSubmitButton"
          startIcon={<CheckRounded />}
          variant="contained"
          type="submit"
          loading={isLoading}
          loadingPosition="start"
        >
          {t("saveFormButton")}
        </LoadingButton>
      )}
      {currentViewingMode === "viewing" && (
        <>
          {isSendTestEventButtonVisible && (
            <AutorisationWrapper
              atLeastOnePermissionOf={[Permission.SendTestEvent]}
            >
              <Button
                data-testid="deviceSendTestEventButton"
                startIcon={
                  <NotificationsActiveOutlined className="icon-on-text" />
                }
                variant="text"
                onClick={() => openSendTestEventPopup(currentDevice)}
                disabled={isLoading}
              >
                {t("sendTestEventButton")}
              </Button>
            </AutorisationWrapper>
          )}
          {allowUnlinking && !isIs32Device && (
            <AutorisationWrapper
              atLeastOnePermissionOf={[Permission.DeleteDevice]}
            >
              <Button
                data-testid="deviceDetailRemoveButton"
                startIcon={
                  <DeleteForeverOutlinedIcon className="icon-on-text" />
                }
                variant="text"
                onClick={() => handleDelete()}
                disabled={isLoading}
              >
                {t("removeFormButton")}
              </Button>
            </AutorisationWrapper>
          )}
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.UpdateDevice]}
          >
            <Button
              data-testid="deviceDetailEditButton"
              startIcon={
                <ModeEditOutlineOutlinedIcon className="icon-on-text" />
              }
              variant="text"
              onClick={() => handleSetEditMode()}
              disabled={isLoading}
            >
              {t("editFormButton")}
            </Button>
          </AutorisationWrapper>
        </>
      )}

      <IconButton
        aria-label="close"
        className="close-button"
        onClick={() => handleClosePopup()}
        data-testid="devicePopupCloseButton"
      >
        <Close />
      </IconButton>

      {isSendTestEventPopupOpen && <SendTestEventPopup />}
    </div>
  );
}

export default DeviceDetailsPopupHeader;
