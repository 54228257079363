import useIS32, { IS32Hook } from "features/systems/IS32/context/IS32-hook";
import React, { ReactNode } from "react";

const IS32Context = React.createContext<IS32Hook>({} as never);

interface Props {
  children: ReactNode;
}

export const IS32Provider = ({ children }: Readonly<Props>): JSX.Element => {
  return (
    <IS32Context.Provider value={useIS32()}>{children}</IS32Context.Provider>
  );
};

export const useIS32ContextProvider = (): IS32Hook =>
  React.useContext<IS32Hook>(IS32Context);
