import { ReactElement } from "react";
import styles from "./sonevo-slider.module.scss";
import { InputLabel, Slider } from "@mui/material";
import Constants from "style/constants";

interface IProps {
  onChange?: (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => void;
  value?: number;
  label: string;
  disabled: boolean;
  sliderIconStart: ReactElement;
  sliderIconEnd: ReactElement;
}

const sliderMarks = [
  { value: 1, label: "1" },
  { value: 2 },
  { value: 3 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9, label: "9" },
];

export function SonevoSlider({
  label,
  sliderIconStart,
  sliderIconEnd,
  value,
  disabled,
  onChange,
}: Readonly<IProps>): ReactElement {
  return (
    <div className={styles.sliderColumn}>
      <InputLabel>{label}</InputLabel>
      <div className={styles.sliderRow}>
        {sliderIconStart}
        <Slider
          sx={{
            "& .MuiSlider-valueLabel": {
              backgroundColor: Constants.Colors.primaryContainer,
              color: Constants.Colors.onPrimaryContainer,
            },
          }}
          value={value}
          valueLabelDisplay="auto"
          shiftStep={1}
          step={1}
          marks={sliderMarks}
          min={1}
          max={9}
          disabled={disabled}
          onChange={onChange}
        />
        {sliderIconEnd}
      </div>
    </div>
  );
}
export default Slider;
