import { Divider as MuiDivider, Typography } from "@mui/material";
import styles from "./divider.component.module.scss";
import { ReactElement } from "react";

interface IProps {
  className?: string;
  startText?: string;
  endElement?: ReactElement;
}

function Divider(props: Readonly<IProps>): ReactElement {
  return (
    <div className={`${styles.dividerContainer} ${props.className}`}>
      {props.startText && (
        <Typography className={styles.startText} variant="h5">
          {props.startText}
        </Typography>
      )}
      <MuiDivider />
      {props.endElement}
    </div>
  );
}

export default Divider;
