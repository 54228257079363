import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Button,
  Typography,
} from "@mui/material";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import styles from "features/systems/IS32/views/IS32-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import IS32ConfigurationAccordionDetailsItem from "features/systems/IS32/views/IS32-configuration-accordion-details-item";
import IS32EditConfigurationPopup from "features/systems/IS32/views/IS32-edit-configuration.component";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";

interface Props {
  unit: IS32ConfigurationUnit;
}

const IS32ConfigurationAccordionItem = ({ unit }: Props): JSX.Element => {
  const { t } = useTranslation("IS32");

  const { systemsDetailsHook } = useSystemsContextProvider();

  const { openConfigurationDetailsInEditingMode } =
    useIS32DetailsContextProvider();

  const { viewingMode } = systemsDetailsHook;

  const accordionStyle = {
    minHeight: 0,
    padding: 0,
    "& .MuiAccordionSummary-content": {
      paddingTop: "8px",
      paddingBottom: "8px",
      margin: 0,
    },
    "&.Mui-expanded": {
      margin: 0,
      minHeight: 0,

      "& .MuiAccordionSummary-content": {
        paddingTop: "8px",
        paddingBottom: "8px",
        margin: 0,
      },
    },
  };

  const formatUnitName = (unit: IS32ConfigurationUnit) => {
    const type = unit.type && unit.type.trim() !== "" ? unit.type : "";
    const serial = unit.serial ? `-${unit.serial}` : "";
    const room = unit.room && unit.room.trim() !== "" ? `-${unit.room}` : "";
    return `${type}${serial}${room}`;
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openConfigurationDetailsInEditingMode();
  };

  return (
    <>
      <Accordion
        key={unit.address}
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: alpha(Constants.Colors.primary, 0.16),
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={accordionStyle}>
          <div className={styles.headerContainer}>
            {unit.localBusUnits?.length === 1 ? (
              <Typography variant="h5">
                {t("configuration.unitTitleSingularLocalBusUnits", {
                  address: unit.address,
                  name: formatUnitName(unit),
                  amountOfLocalBusUnits: unit.localBusUnits?.length,
                })}
              </Typography>
            ) : (
              <Typography variant="h5">
                {t("configuration.unitTitlePluralLocalBusUnits", {
                  address: unit.address,
                  name: formatUnitName(unit),
                  amountOfLocalBusUnits:
                    unit.localBusUnits === null
                      ? 0
                      : unit.localBusUnits?.length,
                })}
              </Typography>
            )}
            <AutorisationWrapper
              atLeastOnePermissionOf={[
                Permission.UpdateSystemsGatewaysConnectors,
              ]}
            >
              <Button
                startIcon={
                  <ModeEditOutlineOutlinedIcon
                    color="primary"
                    className={
                      viewingMode === "editing" ? styles.disabledIcon : ""
                    }
                  />
                }
                variant="text"
                onClick={(event) => handleButtonClick(event)}
                onFocus={(event) => event.stopPropagation()}
                disabled={viewingMode === "editing"}
                className={styles.editButton}
              >
                {t("actions.edit")}
              </Button>
            </AutorisationWrapper>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <IS32ConfigurationAccordionDetailsItem unit={unit} />
        </AccordionDetails>
      </Accordion>
      <IS32EditConfigurationPopup />
    </>
  );
};

export default IS32ConfigurationAccordionItem;
