import { Switch, Typography } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-acoustic-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Divider from "components/divider/divider.component";
import { Controller, useFormContext } from "react-hook-form";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";

const IS32AcousticConfiguration = (): JSX.Element => {
  const { t } = useTranslation("IS32");

  const { selectedIS32ConfigurationUnit, viewingMode } =
    useIS32DetailsContextProvider();
  const { control } = useFormContext();

  const disabled =
    selectedIS32ConfigurationUnit === undefined || viewingMode === "viewing";

  return (
    <div className={styles.acousticConfigurationContainer}>
      <Divider
        className={styles.divider}
        startText={t("configuration.acousticConfiguration.title")}
      />
      <div className={styles.contentContainer}>
        <div className={styles.switchRow}>
          <Typography className={styles.switchRowText} variant="subtitle1">
            {t("configuration.acousticConfiguration.acousticDelay")}
          </Typography>
          <Controller
            name="acousticMonitoringWait30Seconds"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                id="acousticMonitoringWait30SecondsInput"
                checked={field.value}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default IS32AcousticConfiguration;
