import { CommentOutlined } from "@mui/icons-material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import PopUp from "components/pop-up/pop-up.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import IntegrationsNavigation from "features/integrations/views/integrations-navigation";
import { findUpstreamParentBranch } from "features/organisation/domain/models/organisation-tree-node";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { System } from "features/systems/domain/models/system";
import SystemDetailsPopup from "features/systems/views/details/system-details-popup";
import styles from "features/systems/views/systems.module.scss";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { NestedKeyof } from "utils/nested-keyof-utils";

const Systems = (): JSX.Element => {
  const { t } = useTranslation("systems");
  const { hasPermission } = useAuth();
  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const {
    systemsResponse,
    systemsIsLoading,
    systemsIsSuccess,

    systemsFilterHook,
    systemsDetailsHook,

    openDetailsInViewingModeAndRefresh,
    openDetailsInEditingModeAndRefresh,
  } = useSystemsContextProvider();

  const {
    selectedSystem,
    mutationIsLoading,
    isDetailsOpen,
    isDeleteConfirmationPopupOpen,
    isDeleteErrorPopupOpen,
    deleteSystem,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,
    closeDeleteErrorPopup,
  } = systemsDetailsHook;

  const { systems, total } = systemsResponse ?? { systems: [], total: 0 };
  const showSystemsTable = !systemsIsLoading && systemsIsSuccess;

  const organisationBreadCrumb = (system: System): JSX.Element => {
    if (!system.organisationUnitId || !organisationUnitTree) return <></>;

    const upstreamParentBranch = findUpstreamParentBranch(
      organisationUnitTree,
      system.organisationUnitId,
      [],
    );

    if (!upstreamParentBranch) return <></>;

    return (
      <OrganisationBreadCrumb
        rootNodeId={system.organisationUnitId}
        upstreamParentBranch={upstreamParentBranch}
        maxVisibleBreadCrumbs={2}
      />
    );
  };

  const noteIcon = (hasNote: boolean) => (
    <CommentOutlined
      sx={{
        width: 16,
        height: 16,
        color: Constants.Colors.onSurfaceVariant,
        visibility: hasNote ? "visible" : "hidden",
      }}
    />
  );

  const columns: ColumnDefinition<System, NestedKeyof<System>>[] = [
    { key: "name", label: t("table.column.name") },
    {
      key: "type",
      label: t("table.column.type"),
      renderCustomContentProvider: ({ type }) =>
        t(`details.information.type.${type}`),
      disableSort: true,
    },
    {
      key: "organisationUnitId",
      label: t("table.column.organisation"),
      renderCustomContentProvider: organisationBreadCrumb,
      disableSort: true,
      tableCellProps: { width: "400px" },
    },
    {
      key: "note",
      label: "",
      renderCustomContentProvider: ({ note }) => noteIcon(!!note),
      disableSort: true,
    },
  ];

  const getOverflowMenuItems = ():
    | Array<TableOverflowMenuItem<System>>
    | undefined => {
    let menuItems: Array<TableOverflowMenuItem<System>> = [
      {
        label: t("table.overflowMenu.view"),
        action: (system) => openDetailsInViewingModeAndRefresh(system),
      },
    ];

    if (hasPermission(Permission.UpdateSystemsGatewaysConnectors)) {
      menuItems.push({
        label: t("table.overflowMenu.edit"),
        action: (system) => openDetailsInEditingModeAndRefresh(system),
      });
    }
    if (hasPermission(Permission.DeleteSystemsGatewaysConnectors)) {
      menuItems.push({ label: "divider" });
      menuItems.push({
        label: t("table.overflowMenu.delete"),
        action: (system) => deleteSystem(system),
      });
    }

    return menuItems;
  };

  return (
    <div className={styles.container}>
      <PageHeader
        title={t("pageTitle")}
        navigationComponent={<IntegrationsNavigation />}
      />
      {systemsIsLoading && <LoadingIndicator />}
      {showSystemsTable && (
        <Table
          data={systems.map<IRowItem<System>>((system) => ({ data: system }))}
          columns={columns}
          onItemClick={(system: System) =>
            openDetailsInViewingModeAndRefresh(system)
          }
          overflowMenuOptions={getOverflowMenuItems()}
          rowIdentifier={(item) => item.id!}
          initialOrderBy={
            systemsFilterHook.table.sortFromSessionStorage!.property
          }
          initialOrderDirection={
            systemsFilterHook.table.sortFromSessionStorage!.isAscending
              ? "asc"
              : "desc"
          }
          useBuiltInSorter
          enablePagination
          count={total}
          onPageChanged={systemsFilterHook.table.handleOnPageChanged}
          rowsPerPage={systemsFilterHook.table.currentRowsPerPage}
          page={systemsFilterHook.table.currentPage}
          onRowsPerPageChanged={
            systemsFilterHook.table.handleOnRowsPerPageChanged
          }
          onSortChanged={systemsFilterHook.table.handleOnSortChanged}
        />
      )}
      {isDetailsOpen && <SystemDetailsPopup />}
      <PopUp
        isOpen={isDeleteConfirmationPopupOpen}
        title={t("details.deleteSystemConfirmation.title", {
          systemName: selectedSystem?.name,
        })}
        body={t("details.deleteSystemConfirmation.body")}
        primaryButtonText={t("details.deleteSystemConfirmation.confirm")}
        secondaryButtonText={t("details.deleteSystemConfirmation.cancel")}
        handleOnClose={closeDeleteConfirmationPopup}
        secondaryButtonAction={closeDeleteConfirmationPopup}
        primaryButtonAction={() => deleteConfirmationAction?.()}
        isLoadingPrimaryAction={mutationIsLoading}
      />
      <PopUp
        isOpen={isDeleteErrorPopupOpen}
        title={t("details.deleteSystemError.title", {
          systemName: selectedSystem?.name,
        })}
        body={t("details.deleteSystemError.body", {
          systemName: selectedSystem?.name,
        })}
        primaryButtonText={t("details.deleteSystemError.confirm")}
        handleOnClose={closeDeleteErrorPopup}
        primaryButtonAction={closeDeleteErrorPopup}
      />
    </div>
  );
};

export default Systems;
