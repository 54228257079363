import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Checkbox,
  Divider,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import OverflowMenu, {
  OverflowMenuItem,
} from "components/overflow-menu/overflow-menu-component";
import { ColumnDefinition } from "components/table/columnDefinition";
import {
  OrderDirection,
  TableHeader,
} from "components/table/header/table-header";
import "components/table/table.scss";
import { Fragment, ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  descendingCaseInsensitiveStringComparator,
  descendingComparator,
} from "utils/comparator-utils";
import { NestedKeyof, ResolveByNestedKey } from "utils/nested-keyof-utils";

export interface TableOverflowMenuItem<DataType> {
  label: string;
  action?: (item: DataType) => void;
  disabled?: (item: DataType) => boolean;
  isVisible?: (item: DataType) => boolean;
}

export interface IRowItem<DataType> {
  isHighlighted?: boolean;
  isClickable?: boolean;
  showColorOnHover?: boolean;
  hideOverflowMenuOptions?: boolean;
  data: DataType;
}

interface IProps<DataType extends object, Key extends NestedKeyof<DataType>> {
  data: IRowItem<DataType>[];
  currentItem?: DataType;
  columns: ColumnDefinition<DataType, Key>[];
  className?: string;
  onItemClick?: (item: DataType) => void;
  overflowMenuOptions?: Array<TableOverflowMenuItem<DataType>>;
  rowsAreSelectable?: boolean;
  onSelectedRowsChanged?: (items: DataType[]) => void;
  selectedRows?: IRowItem<DataType>[];
  onSelectAllRowsChanged?: (checked: boolean) => void;
  onRowSelected?: (item: DataType) => void;
  onRowDeselected?: (item: DataType) => void;
  allRowsAreSelected?: boolean;
  count?: number;
  enablePagination?: boolean;
  onPageChanged?: (skip: number) => void;
  rowsPerPage?: number;
  onSortChanged?: (property: Key, isAscending: boolean) => void;
  useBuiltInSorter?: boolean;
  page?: number;
  onRowsPerPageChanged?: (rowsPerPage: number) => void;
  rowIdentifier: (item: DataType) => string;
  initialOrderBy?: Key;
  initialOrderDirection?: OrderDirection;
}

export default function Table<
  DataType extends object,
  Key extends NestedKeyof<DataType>,
>(props: Readonly<IProps<DataType, Key>>): ReactElement {
  const { t } = useTranslation("table");

  const [orderBy, setOrderBy] = useState<Key>(
    props.initialOrderBy ?? props.columns[0].key,
  );
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(
    props.initialOrderDirection ?? "asc",
  );
  const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [clickedRow, setClickedRow] = useState<DataType | null>(null);
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<IRowItem<DataType>[]>([]);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const handleRequestSort = (property: Key) => {
    let isCurrentlyAscending = orderBy === property && orderDirection === "asc";
    props.onSortChanged?.(property, !isCurrentlyAscending);
    setOrderDirection(isCurrentlyAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  function getComparator(
    orderDirection: OrderDirection,
    orderBy: Key,
  ): ((a: IRowItem<DataType>, b: IRowItem<DataType>) => number) | undefined {
    const orderByNestedKey = `data.${orderBy}` as NestedKeyof<
      IRowItem<DataType>
    >;
    return orderDirection === "desc"
      ? (a, b) =>
          typeof ResolveByNestedKey<IRowItem<DataType>>(orderByNestedKey, a) ===
          "string"
            ? descendingCaseInsensitiveStringComparator(a, b, orderByNestedKey)
            : descendingComparator(a, b, orderByNestedKey)
      : (a, b) =>
          typeof ResolveByNestedKey<IRowItem<DataType>>(orderByNestedKey, a) ===
          "string"
            ? -descendingCaseInsensitiveStringComparator(a, b, orderByNestedKey)
            : -descendingComparator(a, b, orderByNestedKey);
  }

  function getData(): IRowItem<DataType>[] {
    return !props.useBuiltInSorter
      ? props.data
      : [...props.data].sort(getComparator(orderDirection, orderBy));
  }

  function getSelectedRows(): IRowItem<DataType>[] {
    return props.selectedRows ?? selectedRows;
  }

  function mapMenuItems(
    menuItems: Array<TableOverflowMenuItem<DataType>>,
    rowData: DataType,
  ): Array<OverflowMenuItem> {
    return menuItems
      .filter((item) => {
        if (typeof item === "string") {
          return true;
        }
        return item.isVisible ? item.isVisible(rowData) : true;
      })
      .map((item) => mapMenuItem(item, rowData));
  }

  function mapMenuItem(
    item: TableOverflowMenuItem<DataType>,
    rowData: DataType,
  ): OverflowMenuItem {
    if (item.label === "divider") {
      return "divider";
    }

    return {
      label: item.label,
      action: () => item.action?.(rowData),
      disabled: item.disabled?.(rowData) ?? false,
    };
  }

  function onMoreClick(
    event: React.MouseEvent<HTMLElement>,
    row: IRowItem<DataType>,
    rowIndex: number,
  ): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOverflowMenuOpen(true);
    setClickedRow(row.data);
    setClickedRowIndex(rowIndex);
  }

  function closeOverflowMenu(): void {
    setIsOverflowMenuOpen(false);
    setClickedRow(null);
    setClickedRowIndex(null);
  }

  function handleChangePage(event: unknown, newPage: number): void {
    tableContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    props.onPageChanged?.(newPage);
  }

  function handleChangeRowsPerPage(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    props.onRowsPerPageChanged?.(parseInt(event.target.value));
  }

  function handleOnRowCheckboxChanged(
    checked: boolean,
    row: IRowItem<DataType>,
  ): void {
    let newSelectedRows = [...getSelectedRows()];

    if (checked) {
      newSelectedRows = [...newSelectedRows, row];
      props.onRowSelected?.(row.data);
    } else {
      newSelectedRows = newSelectedRows.filter(
        (selectedRow) => JSON.stringify(selectedRow) !== JSON.stringify(row),
      );
      props.onRowDeselected?.(row.data);
    }

    if (!props.selectedRows) {
      setSelectedRows(newSelectedRows);
    }
    props.onSelectedRowsChanged?.(newSelectedRows.map((row) => row.data));
  }

  function handleOnRowClicked(row: IRowItem<DataType>) {
    if (props.onItemClick) {
      if (row.isClickable || row.isClickable === undefined) {
        props.onItemClick(row.data);
      }
    } else if (props.rowsAreSelectable) {
      handleOnRowCheckboxChanged(
        !getSelectedRows().some(
          (item) => JSON.stringify(item) === JSON.stringify(row),
        ),
        row,
      );
    }
  }

  function getCursorStyling(row: IRowItem<DataType>) {
    if (
      props.onItemClick &&
      (row.isClickable || row.isClickable === undefined)
    ) {
      return { cursor: "pointer" };
    }
  }

  function renderColumns(
    row: IRowItem<DataType>,
    rowIndex: number,
  ): ReactElement {
    return (
      <>
        {props.columns.map((column, columnIndex) => (
          <TableCell
            className={`table-cell 
              ${
                "isHighlighted" in row && row.isHighlighted
                  ? "table-cell-highlighted"
                  : ""
              }`}
            key={`table-cell-${rowIndex}-${columnIndex}`}
            data-testid={`table-cell-${rowIndex}-${columnIndex}`}
            {...column.tableCellProps}
          >
            {column.renderCustomContentProvider
              ? column.renderCustomContentProvider(row.data)
              : (ResolveByNestedKey<DataType>(column.key, row.data) as string)}
          </TableCell>
        ))}
      </>
    );
  }

  function renderDivider(): ReactElement {
    return (
      <TableRow>
        {props.columns.map((column) => (
          <TableCell
            key={`column-divider-${column.key}`}
            className="divider-cell"
          >
            <Divider className="divider" />
          </TableCell>
        ))}
        {props.overflowMenuOptions && (
          <TableCell className="divider-cell">
            <Divider className="divider" />
          </TableCell>
        )}
        {props.rowsAreSelectable && (
          <TableCell className="divider-cell">
            <Divider className="divider" />
          </TableCell>
        )}
      </TableRow>
    );
  }

  return (
    <>
      <TableContainer
        className={
          props.className
            ? `${props.className} table-container`
            : "table-container"
        }
        ref={tableContainerRef}
      >
        <MuiTable stickyHeader aria-label="sticky table">
          <TableHeader
            columns={props.columns}
            orderDirection={orderDirection}
            orderBy={orderBy.toString()}
            onRequestSort={handleRequestSort}
            hasOverflowMenu={!!props.overflowMenuOptions}
            rowsAreSelectable={props.rowsAreSelectable}
            onSelectAllRowsChanged={props.onSelectAllRowsChanged}
            allRowsSelected={props.allRowsAreSelected}
          />

          <TableBody>
            {renderDivider()}
            {props.data.length === 0 && (
              <TableRow className="no-hover-table-row">
                <TableCell
                  className="table-cell"
                  data-testid="no-items-table-row"
                  colSpan={
                    props.rowsAreSelectable
                      ? props.columns.length + 1
                      : props.columns.length
                  }
                  align={"center"}
                >
                  {t("noItemsText")}
                </TableCell>
              </TableRow>
            )}
            {props.data.length > 0 && (
              <>
                {getData().map((row, rowIndex) => {
                  return (
                    <Fragment
                      key={`table-row-${props.rowIdentifier(row.data)}`}
                    >
                      <TableRow
                        data-testid={`table-row-${rowIndex}`}
                        className={`${
                          row.showColorOnHover === false
                            ? "no-hover-table-row"
                            : ""
                        } ${
                          row.isHighlighted
                            ? "table-row-highlighted"
                            : "table-row"
                        }`}
                        selected={row.data === props.currentItem}
                        onClick={() => handleOnRowClicked(row)}
                        style={getCursorStyling(row)}
                      >
                        {props.rowsAreSelectable && (
                          <TableCell padding="checkbox" className="table-cell">
                            <Checkbox
                              data-testid={`selection-checkbox-${rowIndex}`}
                              className={`selection-checkbox ${
                                getSelectedRows().find(
                                  (selectedRow) =>
                                    JSON.stringify(selectedRow) ===
                                    JSON.stringify(row),
                                ) !== undefined
                                  ? "is-checked"
                                  : ""
                              }`}
                              color="primary"
                              checked={
                                getSelectedRows().find(
                                  (selectedRow) =>
                                    JSON.stringify(selectedRow) ===
                                    JSON.stringify(row),
                                ) !== undefined
                              }
                              onChange={(_, checked) =>
                                handleOnRowCheckboxChanged(checked, row)
                              }
                              // prettier-ignore
                              // @ts-ignore
                              inputProps={{ "data-testid": `checkbox-cell-for-row-${rowIndex}` }}
                            />
                          </TableCell>
                        )}
                        {renderColumns(row, rowIndex)}
                        {props.overflowMenuOptions &&
                          !row.hideOverflowMenuOptions && (
                            <TableCell
                              align="right"
                              width="24px"
                              className={`table-cell 
                                ${
                                  "isHighlighted" in row && row.isHighlighted
                                    ? "table-cell-highlighted"
                                    : ""
                                }`}
                            >
                              <IconButton
                                data-testid={`more-icon-button-${rowIndex}`}
                                className={`more-icon-button ${
                                  clickedRowIndex === rowIndex
                                    ? "is-selected"
                                    : ""
                                }`}
                                onClick={(
                                  event: React.MouseEvent<
                                    HTMLElement,
                                    MouseEvent
                                  >,
                                ) => onMoreClick(event, row, rowIndex)}
                              >
                                <MoreHorizIcon className="more-icon" />
                              </IconButton>
                            </TableCell>
                          )}
                      </TableRow>

                      {renderDivider()}
                    </Fragment>
                  );
                })}
              </>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {props.enablePagination &&
        props.rowsPerPage !== undefined &&
        props.count !== undefined &&
        props.page !== undefined && (
          <TablePagination
            data-testid="table-paginator"
            className="table-paginator"
            rowsPerPageOptions={[25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            count={props.count}
            rowsPerPage={props.rowsPerPage}
            labelRowsPerPage={t("rowsPerPage")}
            labelDisplayedRows={(paginationInfo) =>
              `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${
                paginationInfo.count
              }`
            }
            getItemAriaLabel={(type) => t(`${type}Pagination`)}
            page={props.page}
            onPageChange={handleChangePage}
            showFirstButton={true}
            showLastButton={true}
          />
        )}
      {props.overflowMenuOptions && clickedRow && (
        <OverflowMenu
          isOpen={isOverflowMenuOpen}
          onClose={closeOverflowMenu}
          anchorEl={anchorEl}
          menuItems={mapMenuItems(props.overflowMenuOptions, clickedRow)}
        />
      )}
    </>
  );
}
