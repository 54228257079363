import { Collapse, Switch, Tooltip, Typography, Zoom } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-configuration.module.scss";
import Alert from "components/alert/alert.component";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";

const GatewaySettingsConfiguration = (): JSX.Element => {
  const { t } = useTranslation("gateway");

  const {
    gatewayDetailsHook: {
      isTogglingSonevoApiDisabled,
      isTogglingMediaServerDisabled,
      currentSelectedGateway,
      viewingMode,
    },
  } = useGatewaysContextProvider();

  const {
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const isViewing = viewingMode === "viewing";
  const isEditing = viewingMode === "editing";

  const isAlertVisible =
    isEditing &&
    (dirtyFields.isSonevoApiEnabled ||
      dirtyFields.isAlarmmonitorEnabled ||
      dirtyFields.isAudioEnabled ||
      dirtyFields.isMediaserverEnabled ||
      dirtyFields.isKadexApiEnabled ||
      dirtyFields.isIS32ApiEnabled);

  return (
    <div className={styles.container}>
      <Collapse in={isAlertVisible}>
        <div className={styles.explanationRow}>
          <Alert body={t("details.settings.configurationRemark")} />
        </div>
      </Collapse>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.information.automaticDeviceCreation")}
        </Typography>
        <Controller
          name="shouldCreateDevicesAutomatically"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <Tooltip
        title={
          isEditing && isTogglingSonevoApiDisabled(currentSelectedGateway)
            ? t("details.settings.configurationDisabledTooltip")
            : ""
        }
        followCursor
        TransitionComponent={Zoom}
      >
        <div className={styles.configurationRow}>
          <Typography variant="subtitle1">
            {t("details.settings.configuration.sonevoApi")}
          </Typography>
          <Controller
            name="isSonevoApiEnabled"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                type="checkbox"
                checked={field.value}
                disabled={
                  isViewing ||
                  (isEditing &&
                    isTogglingSonevoApiDisabled(currentSelectedGateway))
                }
              />
            )}
          />
        </div>
      </Tooltip>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.settings.configuration.alarmMonitor")}
        </Typography>
        <Controller
          name="isAlarmmonitorEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.settings.configuration.audioModule")}
        </Typography>
        <Controller
          name="isAudioEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <Tooltip
        title={
          isEditing && isTogglingMediaServerDisabled(currentSelectedGateway)
            ? t("details.settings.configurationDisabledTooltip")
            : ""
        }
        followCursor
        TransitionComponent={Zoom}
      >
        <div className={styles.configurationRow}>
          <Typography variant="subtitle1">
            {t("details.settings.configuration.mediaserverModule")}
          </Typography>
          <Controller
            name="isMediaserverEnabled"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                type="checkbox"
                checked={field.value}
                disabled={
                  isViewing ||
                  (isEditing &&
                    isTogglingMediaServerDisabled(currentSelectedGateway))
                }
              />
            )}
          />
        </div>
      </Tooltip>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1" className={styles.configurationTitle}>
          {t("details.settings.configuration.kadexApi")}
        </Typography>
        <Controller
          name="isKadexApiEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1" className={styles.configurationTitle}>
          {t("details.settings.configuration.is32Api")}
        </Typography>
        <Controller
          name="isIS32ApiEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
    </div>
  );
};

export default GatewaySettingsConfiguration;
