import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import PopUp from "components/pop-up/pop-up.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Role from "features/role/domain/models/role";
import {
  useDeleteRoleMutation,
  useGetRolesQuery,
} from "features/role/domain/reducers/role.reducer";
import RoleDetailsPopup from "features/role/views/role-details/role-details-popup.component";
import "features/role/views/roles.scss";
import ObjectSort from "models/object-sort";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiResponse, isErrorResponse } from "redux-base/create-api-utils";
import { useAppDispatch } from "redux-base/store";
import { useSessionStorage } from "usehooks-ts";
import { NestedKeyof } from "utils/nested-keyof-utils";
import { ViewingMode } from "utils/viewing-utils";

function Roles(): ReactElement {
  const { t } = useTranslation("role");
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();
  const [deleteRole] = useDeleteRoleMutation();
  const sessionStorageSortingKey = "role-sorting";

  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<Role>
  >(sessionStorageSortingKey, { property: "name", isAscending: true });

  const columns: ColumnDefinition<Role, NestedKeyof<Role>>[] = [
    { key: "name", label: t("nameHeaderLabel") },
    {
      key: "roleType",
      label: t("roleTypeHeaderLabel"),
      renderCustomContentProvider(role) {
        return <>{t(`roleType.${role.roleType}`)}</>;
      },
    },
    { key: "idpId", label: t("idpIdHeaderLabel") },
  ];

  const { data, error, isLoading, isSuccess } = useGetRolesQuery();

  const [currentRole, setCurrentRole] = useState<Role>();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [detailsViewingMode, setDetailsViewingMode] =
    useState<ViewingMode>("none");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );

  function openDetails(
    viewingMode: ViewingMode,
    role: Role | undefined = undefined,
  ): void {
    setCurrentRole(role);
    setIsDetailsOpen(true);
    setDetailsViewingMode(viewingMode);
  }

  function onRoleSaved(role: Role): void {
    setCurrentRole(role);
  }

  function closeDetails() {
    setIsDetailsOpen(false);
    setCurrentRole(undefined);
  }

  useEffect(() => {
    if (error) {
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  function handleDeleteRole(role: Role): void {
    setIsDeleteConfirmationOpen(true);
    setDeleteConfirmationAction(() => () => confirmDeleteRole(role));
  }

  async function confirmDeleteRole(role: Role): Promise<void> {
    const result = (await deleteRole(role.idpId)) as ApiResponse<void>;

    if (isErrorResponse(result)) {
      dispatch(
        setErrorMessage({
          error: result.error,
        }),
      );
    }

    setDeleteConfirmationAction(() => () => {});
    setIsDeleteConfirmationOpen(false);
    setIsDetailsOpen(false);
  }

  function getOverflowMenuItems():
    | Array<TableOverflowMenuItem<Role>>
    | undefined {
    let menuItems = new Array<TableOverflowMenuItem<Role>>();
    if (hasPermission(Permission.ReadRole)) {
      menuItems.push({
        label: t("overflowMenu.view"),
        action: (role) => openDetails("viewing", role),
      });
    }
    if (hasPermission(Permission.UpdateRole)) {
      menuItems.push({
        label: t("overflowMenu.edit"),
        action: (role) => openDetails("editing", role),
      });
    }
    if (hasPermission(Permission.DeleteRole)) {
      menuItems.push({ label: "divider" });
      menuItems.push({
        label: t("overflowMenu.delete"),
        action: (role) => handleDeleteRole(role),
      });
    }

    if (menuItems.length === 0) {
      return;
    } else if (menuItems.length === 2) {
      menuItems.splice(1, 0, { label: "divider" });
    }

    return menuItems;
  }

  return (
    <div className="roles-container" data-testid="rolesContainer">
      <PageHeader
        title={t("pageTitle")}
        addActionPermission={Permission.CreateRole}
        isAddActionVisible={isSuccess}
        onAdd={() => openDetails("creation")}
      />
      {isLoading && <LoadingIndicator />}
      {isSuccess && data && (
        <Table
          className="roles-container"
          data={data.map<IRowItem<Role>>((role) => ({ data: role }))}
          columns={columns}
          onItemClick={(item: Role) => openDetails("viewing", item)}
          overflowMenuOptions={getOverflowMenuItems()}
          rowIdentifier={(item: Role) => item.idpId}
          onSortChanged={(property: NestedKeyof<Role>, isAscending: boolean) =>
            setSortFromSessionStorage({ property, isAscending })
          }
          initialOrderBy={sortFromSessionStorage.property}
          initialOrderDirection={
            sortFromSessionStorage.isAscending ? "asc" : "desc"
          }
          useBuiltInSorter
        />
      )}
      {isDetailsOpen && (
        <RoleDetailsPopup
          selectedRole={currentRole}
          allRoles={data}
          defaultViewingMode={detailsViewingMode}
          isOpen={isDetailsOpen}
          onClose={closeDetails}
          onSaved={onRoleSaved}
          onDelete={() => currentRole && handleDeleteRole(currentRole)}
        />
      )}
      <PopUp
        isOpen={isDeleteConfirmationOpen}
        title={t("deleteRoleConfirmation.title", {
          roleName: currentRole?.name,
        })}
        body={t("deleteRoleConfirmation.bodyText")}
        primaryButtonText={t("deleteRoleConfirmation.confirmButton")}
        secondaryButtonText={t("deleteRoleConfirmation.cancelButton")}
        handleOnClose={() => setIsDeleteConfirmationOpen(false)}
        secondaryButtonAction={() => setIsDeleteConfirmationOpen(false)}
        primaryButtonAction={() => deleteConfirmationAction()}
      />
    </div>
  );
}

export default Roles;
