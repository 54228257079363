import { ReactElement } from "react";

interface IProps {
  color?: string;
}

export default function ScanSearchIcon(props: Readonly<IProps>): ReactElement {
  const color = props.color ?? "#000000";

  return (
    <svg width="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.25 4.25V2.75C1.25 2.35218 1.40804 1.97064 1.68934 1.68934C1.97064 1.40804 2.35218 1.25 2.75 1.25H4.25M11.75 1.25H13.25C13.6478 1.25 14.0294 1.40804 14.3107 1.68934C14.592 1.97064 14.75 2.35218 14.75 2.75V4.25M14.75 11.75V13.25C14.75 13.6478 14.592 14.0294 14.3107 14.3107C14.0294 14.592 13.6478 14.75 13.25 14.75H11.75M4.25 14.75H2.75C2.35218 14.75 1.97064 14.592 1.68934 14.3107C1.40804 14.0294 1.25 13.6478 1.25 13.25V11.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8 10.25C9.24264 10.25 10.25 9.24264 10.25 8C10.25 6.75736 9.24264 5.75 8 5.75C6.75736 5.75 5.75 6.75736 5.75 8C5.75 9.24264 6.75736 10.25 8 10.25Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M11.0002 11.0002L9.5752 9.5752"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}
