import { TreeItem, treeItemClasses, TreeItemProps } from "@mui/lab";
import { alpha, Box, styled, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Divider from "components/divider/divider.component";
import "./organisation-unit-tree-item-component.scss";
import "style/constants.scss";
import Constants from "style/constants";
import { ReactElement } from "react";

interface OrganisationUnitTreeItemProps extends TreeItemProps {
  labelText: string;
  isRoot: boolean;
  startingIcons?: ReactElement;
  endingIcons?: ReactElement;
  disableSelection: boolean;
  highlightColor?: string;
  highlightedHoverColor?: string;
}

export default function OrganisationUnitTreeItem(
  props: Readonly<OrganisationUnitTreeItemProps>,
) {
  const {
    labelText,
    isRoot,
    startingIcons,
    endingIcons,
    disableSelection,
    highlightColor,
    highlightedHoverColor,
    ...other
  } = props;

  const OrganisationUnitTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      margin: "8px 0px",
      padding: "0px 4px",
      height: "40px",
      width: "auto",
      borderRadius: "28px",
      minWidth: "350px",
      cursor: disableSelection ? "auto" : "pointer",
      "&:hover": {
        backgroundColor: disableSelection
          ? "transparent"
          : alpha(Constants.Colors.secondaryContainer, 0.16),
      },
      "&.Mui-focused": {
        backgroundColor: "transparent",
      },
      "&.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: disableSelection
          ? alpha(Constants.Colors.onSurface, 0.12)
          : highlightColor ?? Constants.Colors.secondaryContainer,
      },
      "&.Mui-selected:hover": {
        backgroundColor: disableSelection
          ? alpha(Constants.Colors.onSurface, 0.12)
          : highlightedHoverColor ?? Constants.Colors.secondaryContainer,
      },
      "&.MuiTreeItem-content": {
        cursor: disableSelection ? "auto" : "pointer",
      },
      [`& .${treeItemClasses.iconContainer}`]: {
        height: "32px",
        width: "32px",
        alignItems: "center",
        justifyContent: "center",
        margin: "0",
        "&:empty": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: disableSelection
            ? "transparent"
            : alpha("#241a00", 0.16),
          borderRadius: "24px",
        },
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 20,
      paddingLeft: 4,
      borderLeft: `1px solid ${alpha("#71787D29", 0.16)}`,
      marginBottom: "8px",
    },
  }));

  return (
    <>
      {isRoot && <Divider />}
      <OrganisationUnitTreeItemRoot
        collapseIcon={<ArrowDropDownIcon data-testid="collapseIcon" />}
        expandIcon={<ArrowRightIcon data-testid="expandIcon" />}
        label={
          <Box className="tree-item-container">
            <div className="starting-icons-container">{startingIcons}</div>
            <Typography
              sx={{ paddingLeft: "8px" }}
              className="ellipsis"
              variant="subtitle1"
              data-testid={`OrganisationUnitTreeItemLabel-${labelText}`}
            >
              {labelText}
            </Typography>
            <div className="ending-icons-container">{endingIcons}</div>
          </Box>
        }
        {...other}
      />
    </>
  );
}
