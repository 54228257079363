import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";
import Constants from "style/constants";

interface Props {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
}

export default function SolidPersonFallingIcon({
  disabled,
  sx,
  color,
}: Props): ReactElement {
  const determinedColor =
    disabled !== undefined && disabled
      ? Constants.Colors.inactive
      : color ?? "#7342BD";
  return (
    <SvgIcon sx={sx} viewBox="0 0 24 24">
      <path
        d="M13.5 0C14.3297 0 15 0.670312 15 1.5V1.95937C15 4.51875 13.6922 6.8625 11.6016 8.22187L11.6109 8.23594L14.2734 12H18.375C19.0828 12 19.7484 12.3328 20.175 12.9L22.2 15.6C22.6969 16.2609 22.5609 17.2031 21.9 17.7C21.2391 18.1969 20.2969 18.0609 19.8 17.4L18 15H13.4344L17.7609 21.6844C18.2109 22.3781 18.0141 23.3062 17.3156 23.7609C16.6172 24.2156 15.6938 24.0141 15.2391 23.3156L7.71094 11.6812C7.575 12.1125 7.5 12.5719 7.5 13.0406V16.5C7.5 17.3297 6.82969 18 6 18C5.17031 18 4.5 17.3297 4.5 16.5V13.0406C4.5 9.98906 6.35625 7.24219 9.19219 6.10781C10.8891 5.42813 12 3.7875 12 1.95937V1.5C12 0.670312 12.6703 0 13.5 0ZM5.25 6C4.00781 6 3 4.99219 3 3.75C3 2.50781 4.00781 1.5 5.25 1.5C6.49219 1.5 7.5 2.50781 7.5 3.75C7.5 4.99219 6.49219 6 5.25 6Z"
        fill={determinedColor}
      />
    </SvgIcon>
  );
}
