export function isBelowMinimumVersion(
  version: string | undefined,
  minimumVersion: string,
): boolean {
  if (!version) {
    return false;
  }

  const parseVersion = (version: string): number[] => {
    return version.split(".").map(Number);
  };

  const [major1, minor1, patch1] = parseVersion(version);
  const [major2, minor2, patch2] = parseVersion(minimumVersion);

  if (major1 < major2) return true;
  if (major1 > major2) return false;

  if (minor1 < minor2) return true;
  if (minor1 > minor2) return false;

  if (patch1 < patch2) return true;
  if (patch1 > patch2) return false;

  return false;
}
