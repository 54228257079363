import { Typography } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-location-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Divider from "components/divider/divider.component";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";

interface IProps {
  unit: IS32ConfigurationUnit;
}

const IS32LocationConfiguration = ({ unit }: IProps): JSX.Element => {
  const { t } = useTranslation("IS32");

  const { systemsDetailsHook } = useSystemsContextProvider();

  return (
    <div className={styles.container}>
      <Divider className={styles.divider} startText={t("locations.title")} />
      <div className={styles.contentContainer}>
        <Typography
          className={`${
            systemsDetailsHook.viewingMode === "viewing" ? styles.clickable : ""
          }`}
          variant="subtitle1"
          onClick={() => systemsDetailsHook.openDeviceDetails(unit.address)}
        >
          {t("locations.room")}
        </Typography>
      </div>
    </div>
  );
};

export default IS32LocationConfiguration;
