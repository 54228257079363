import { Dialog } from "@mui/material";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { System } from "features/systems/domain/models/system";
import SystemDetailsPopupHeader from "features/systems/views/details/system-details-popup-header";
import SystemInformation from "features/systems/views/details/system-information";
import { FieldValues, FormProvider } from "react-hook-form";
import SystemConfiguration from "./system-configuration";
import { IS32Provider } from "features/systems/IS32/context/IS32-provider";

const SystemDetailsPopup = (): JSX.Element => {
  const { systemsDetailsHook } = useSystemsContextProvider();
  const { selectedSystem, form, isDetailsOpen, closeDetails, updateSystem } =
    systemsDetailsHook;

  const popUpStyle = {
    style: {
      height: "960px",
      width: "1227px",
      maxWidth: "1227px",
      paddingBottom: "0px",
    },
  };

  const submitForm = (fieldValues: FieldValues) => {
    if (!selectedSystem) return;

    const system: System = {
      id: selectedSystem.id,
      type: fieldValues.type,
      name: fieldValues.name?.trim(),
      note: fieldValues.note?.trim(),
      organisationUnitId: fieldValues.organisationUnitId,
      gateway: selectedSystem.gateway,
      lastSystemEvent: undefined,
    };

    updateSystem(system);
  };

  return (
    <Dialog
      open={isDetailsOpen}
      onClose={() => closeDetails(form.formState.isDirty)}
      PaperProps={popUpStyle}
    >
      <FormProvider {...form}>
        <IS32Provider>
          <DetailsPopUpContent
            onSubmit={form.handleSubmit(submitForm)}
            header={<SystemDetailsPopupHeader />}
            leftContent={<SystemInformation />}
            rightContent={<SystemConfiguration />}
          />
        </IS32Provider>
      </FormProvider>
    </Dialog>
  );
};

export default SystemDetailsPopup;
