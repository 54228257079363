import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import {
  useDeleteSystemMutation,
  useUpdateSystemMutation,
  useValidateDeleteSystemMutation,
} from "features/systems/domain/reducers/systems.reducer";
import { System } from "features/systems/domain/models/system";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ViewingMode } from "utils/viewing-utils";
import { useDevices } from "features/device/devices/hooks";

export interface SystemDetailsHook {
  viewingMode: ViewingMode;

  mutationIsLoading: boolean;
  cancelMutation: () => void;

  selectedSystem: System | undefined;

  updateSystem: (system: System) => void;
  deleteSystem: (system: System) => void;

  changeCurrentSelectedSystem: (system: System) => void;

  isDetailsOpen: boolean;
  openDetailsInViewingMode: (system: System) => void;
  openDetailsInEditingMode: (system: System) => void;
  closeDetails: (isFormStateDirty: boolean) => void;

  isDeleteErrorPopupOpen: boolean;
  closeDeleteErrorPopup: () => void;

  isDeleteConfirmationPopupOpen: boolean;
  closeDeleteConfirmationPopup: () => void;
  deleteConfirmationAction: () => void;

  selectedDeviceId: string | undefined;
  openDeviceDetails: (address: string) => void;

  form: UseFormReturn<Partial<System>, any>;
}

const useSystemDetails = (): SystemDetailsHook => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {} as Partial<System>,
  });

  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [isDeleteErrorPopupOpen, setIsDeleteErrorPopupOpen] =
    useState<boolean>(false);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );
  const { openDeviceDetailsPopup } = useDevices();
  const [selectedDeviceId, setSelectedDeviceId] = useState<
    string | undefined
  >();

  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = useState<System>();
  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);

  const [
    updateSystem,
    {
      isLoading: updateSystemIsLoading,
      isSuccess: updateSystemIsSuccess,
      isError: updateSystemIsError,
      error: updateSystemError,
      data: updateSystemData,
    },
  ] = useUpdateSystemMutation();

  const [
    deleteSystemMutation,
    {
      isSuccess: deleteSystemIsSuccess,
      isLoading: deleteSystemIsLoading,
      isError: deleteSystemIsError,
      error: deleteSystemError,
    },
  ] = useDeleteSystemMutation();

  const [
    validateDeleteSystemMutation,
    {
      isSuccess: validateDeleteSystemIsSuccess,
      isError: validateDeleteSystemIsError,
    },
  ] = useValidateDeleteSystemMutation();

  useEffect(() => {
    if (selectedSystem?.id) {
      setFormValuesWithSelectedSystem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSystem]);

  useEffect(() => {
    setMutationIsLoading(updateSystemIsLoading || deleteSystemIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSystemIsLoading, deleteSystemIsLoading]);

  useEffect(() => {
    if (updateSystemIsSuccess && updateSystemData) {
      setSelectedSystem(updateSystemData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSystemIsSuccess]);

  useEffect(() => {
    if (updateSystemIsError && updateSystemError) {
      dispatch(
        setErrorMessage({
          error: updateSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSystemIsError]);

  useEffect(() => {
    if (deleteSystemIsError && deleteSystemError) {
      dispatch(
        setErrorMessage({
          error: deleteSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSystemIsError]);

  useEffect(() => {
    if (deleteSystemIsSuccess) {
      setIsDeleteConfirmationPopupOpen(false);
      setIsDetailsOpen(false);
    }
  }, [deleteSystemIsSuccess]);

  useEffect(() => {
    if (validateDeleteSystemIsSuccess && selectedSystem) {
      setIsDeleteConfirmationPopupOpen(true);
      setDeleteConfirmationAction(
        () => () => confirmDeleteSystem(selectedSystem),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateDeleteSystemIsSuccess]);

  useEffect(() => {
    if (validateDeleteSystemIsError && selectedSystem)
      setIsDeleteErrorPopupOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateDeleteSystemIsError]);

  const setFormValuesWithSelectedSystem = () => {
    form.reset({
      name: selectedSystem?.name ?? "",
      note: selectedSystem?.note ?? "",
      organisationUnitId: selectedSystem?.organisationUnitId ?? undefined,
    });
  };

  const openDetailsInViewingMode = (system?: System) => {
    setSelectedSystem(system);
    setIsDetailsOpen(true);
    setViewingMode("viewing");
  };

  const openDetailsInEditingMode = (system?: System) => {
    setSelectedSystem(system);
    setIsDetailsOpen(true);
    setViewingMode("editing");
    setFormValuesWithSelectedSystem();
  };

  const cancelMutation = () => {
    setViewingMode("viewing");
    setFormValuesWithSelectedSystem();
  };

  const closeDetails = (isFormStateDirty: boolean) => {
    const showConfirmation = viewingMode === "editing" && isFormStateDirty;
    if (!showConfirmation) return setIsDetailsOpen(false);

    dispatch(
      showConfirmationPopup({
        showInstantly: true,
        confirmActionNextAction: () => setIsDetailsOpen(false),
      }),
    );
  };

  const closeDeleteConfirmationPopup = () =>
    setIsDeleteConfirmationPopupOpen(false);

  const closeDeleteErrorPopup = () => setIsDeleteErrorPopupOpen(false);

  const deleteSystem = (system: System) => {
    setSelectedSystem(system);
    validateDeleteSystemMutation(system.id!);
  };

  const confirmDeleteSystem = (system: System) => {
    deleteSystemMutation(system.id!);
    setDeleteConfirmationAction(() => () => {});
    setSelectedSystem(undefined);
  };

  const changeCurrentSelectedSystem = (system: System) => {
    setSelectedSystem(system);
  };

  const openDeviceDetails = (address: string) => {
    if (viewingMode === "viewing") {
      const externalId = `${selectedSystem!.macAddress}_${address}_room`;

      setSelectedDeviceId(
        selectedSystem!.devices?.find((x) => x.externalId === externalId)?.id,
      );
      openDeviceDetailsPopup(false);
    }
  };

  return {
    viewingMode,
    mutationIsLoading,
    cancelMutation,

    changeCurrentSelectedSystem,

    selectedSystem,

    updateSystem,
    deleteSystem,

    isDetailsOpen,
    openDetailsInViewingMode,
    openDetailsInEditingMode,
    closeDetails,

    isDeleteErrorPopupOpen,
    closeDeleteErrorPopup,

    isDeleteConfirmationPopupOpen,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,

    selectedDeviceId,
    openDeviceDetails,

    form,
  };
};

export default useSystemDetails;
